import './App.css';
import './common.css';
import './root.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import { useAuthState } from 'react-firebase-hooks/auth'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import SignIn from './pages/sign-in/sign-in';
import Dashboard from './pages/dashboard/dashboard';
import Scans from './pages/scans/scans';
import NewScan from './pages/new-scan/new-scan';
import Scan from './pages/scan/scan';
import Navbar from './organisms/navbar/navbar';
import User from './pages/user/user';
import DeleteUser from './pages/delete-user/delete-user';
import CreateUser from './pages/create-user/create-user';
import Unavailable from './pages/unavailable/unavailable';
import NewFileScan from './pages/new-file-scan/new-file-scan';

import { Toaster } from 'react-hot-toast';
import ScrollToTop from './molecules/scrollToTop/scrollToTop';
import Footer from './molecules/footer/footer';
import DataAdministration from './pages/data-administration/data-administration';
import DeleteData from './pages/delete-data/delete-data';
import NewTestScan from './pages/new-test-scan/new-test-scan';

import { MantineProvider } from '@mantine/core';
import ExperimentalNewScan from './pages/experimental-new-scan/experimental-new-scan';
import NewOrganization from './pages/new-organization/new-organization';
import { UserAndOrganzationContextProvider } from './contexts/userAndOrganizationContext';
import Organization from './pages/organization/organization';
import JoinOrganizationAuth from './pages/join-organization-auth/join-organization-auth';
import JoinOrganization from './pages/join-organization/join-organization';

import './i18n';
import { Suspense } from 'react';
import DPAForm from './pages/dpa-form/dpa-form';
import Upgrade from './pages/upgrade/upgrade';
import SuccessFullPayment from './pages/successfull-payment/successfull-payment';
import WaitList from './pages/wait-list/wait-list';
import AiScan from './pages/ai-scan/ai-scan';
import ConnectUser from './pages/connect-user/connect-user';
import SuccessfullDeletion from './pages/successfull-deletion/successfull-deletion';

firebase.initializeApp({
    apiKey: "AIzaSyCg6ActQTzDtTwjSBOBx6px-TyGyZlAEnI",
    authDomain: "noai-19c19.firebaseapp.com",
    projectId: "noai-19c19",
    storageBucket: "noai-19c19.appspot.com",
    messagingSenderId: "589275210464",
    appId: "1:589275210464:web:6a7e29cb8fdd8d78f52685"
})

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.app().functions("europe-west1");

function App() {
    const [user] = useAuthState(auth)

    if (user) return (
        <Suspense fallback={<div>loading...</div>}>
            <MantineProvider withGlobalStyles withNormalizeCSS>
                <UserAndOrganzationContextProvider firestore={firestore} auth={auth}>
                    <Router>
                        <Toaster />
                        <ScrollToTop />
                        <Navbar />
                        <Routes>
                            {/*<Route path="/profile/"> <Profile /> </Route>*/}
                            <Route path="/*" element={<Dashboard />} />
                            <Route path="/home" element={<Dashboard />}>  </Route>

                            <Route path="/new-scan" element={<ExperimentalNewScan firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/new-scan/:id" element={<ExperimentalNewScan firestore={firestore} auth={auth} firebase={firebase} />} />

                            <Route path="/check-ai" element={<AiScan firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/check-ai/:id" element={<AiScan firestore={firestore} auth={auth} firebase={firebase} />} />

                            {/* <Route path="/new-file-scan" element={<NewFileScan firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/new-file-scan/:id" element={<NewFileScan firestore={firestore} auth={auth} firebase={firebase} />} /> */}

                            <Route path="/scans" element={<Scans firestore={firestore} auth={auth} />} />
                            <Route path="/scans/:id" element={<Scans firestore={firestore} auth={auth} />} />
                            <Route path="/scan/:id" element={<Scan firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/scan/:id/add-follow-up-questions" element={<Scan firestore={firestore} auth={auth} firebase={firebase} />} />

                            <Route path="/user" element={<User auth={auth} firestore={firestore} />} />
                            <Route path="/delete-user" element={<DeleteUser auth={auth} />} />
                            <Route path="/data-administration" element={<DataAdministration />} />
                            <Route path="/delete-data" element={<DeleteData auth={auth} firestore={firestore} />} />
                            <Route path="/connect-user" element={<ConnectUser />} />

                            <Route path="/unavailable" element={<Unavailable />} />


                            <Route path="/new-organization" element={<NewOrganization firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/new-organization/:dpa" element={<NewOrganization firestore={firestore} auth={auth} firebase={firebase} />} />

                            <Route path="/organization" element={<Organization firestore={firestore} auth={auth} firebase={firebase} />} />
                            <Route path="/DPA/:organizaionId" element={<DPAForm firestore={firestore} auth={auth} firebase={firebase} />} />

                            {/* <Route path="/new-test-scan" element={<NewTestScan firestore={firestore} auth={auth} firebase={firebase} />} /> */}
                            {/* <Route path="/x-new-scan" element={<ExperimentalNewScan firestore={firestore} auth={auth} firebase={firebase} />} /> */}

                            <Route path="/join/:id/:name" element={<JoinOrganization firebase={firebase} />} />

                            <Route path="/upgrade" element={<Upgrade firestore={firestore} auth={auth} firebase={firebase} />} />

                            <Route path="/success" element={<SuccessFullPayment />} />

                            <Route path="/waitlist" element={<WaitList firestore={firestore} auth={auth} firebase={firebase} />} />
                        </Routes>
                        {/* <Footer auth={auth} firestore={firestore} /> */}

                    </Router>
                </UserAndOrganzationContextProvider>
            </MantineProvider>
        </Suspense>
    )

    return (
        <Suspense fallback={<div>loading...</div>}>
            <Router>
                <Routes>
                    <Route path="/*" element={<SignIn auth={auth} />} />
                    <Route path="/sign-up" element={<CreateUser auth={auth} />} />
                    <Route path="/join/:id/:name" element={<JoinOrganizationAuth auth={auth} firestore={firestore} />} />

                    <Route path="/deleted" element={<SuccessfullDeletion />} />
                    {/* <Route path="/adwriter" element={<AdWriter />} /> */}


                </Routes>
            </Router>
        </Suspense>
    );
}

export default App;