import { useEffect, useRef, useState } from "react";

import style from './suggestedComments.module.css';
import { Button, Divider, Flex, Grid, Text, UnstyledButton } from "@mantine/core";

import checkmark from './../../../../assets/checkmark.svg'
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";


const GeneratedCommentTab = ({ scan, auth, firestore, contentScanId, generatedCommentIndex, scrollWrapperRef }) => {
    const { t } = useTranslation(['common']);

    const splittingRegex = /[.]+/;

    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to update the scroll position state
    const handleScroll = () => {
        const position = scrollWrapperRef.current.scrollTop;
        setScrollPosition(position);
    };

    // Use effect to add and remove the event listener
    useEffect(() => {
        const divElement = scrollWrapperRef.current;
        divElement.addEventListener('scroll', handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            divElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const actualGeneratedCommentIndex = scan?.generatedComments?.findIndex(object => object.id === generatedCommentIndex);

    const [sortedSpecificFeedback, setSortedSpecificFeedback] = useState([]);
    const [unspecificFeedback, setUnspecificFeedback] = useState([]);
    const preRef = useRef(null);

    const [indexOfHoveredSentence, setIndexOfHoveredSentence] = useState(null)

    const [elements, setElements] = useState([]);
    const [commentElements, setCommentElements] = useState([]);

    const addFeedbackAsComment = async (feedbackItem) => {

        if (isCommentCollision(feedbackItem, scan.comments)) return;

        const copyOfComments = [...scan.comments];
        const copyOfGeneratedComments = [...scan.generatedComments];

        //Modify specific feedbackItem status
        const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfGeneratedComments[actualGeneratedCommentIndex].comments];
        copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
            ...feedbackItem,
            status: "added",
        }

        //Add modified feedbackItem to feedback-array of feedbackParagraphs[0]
        copyOfGeneratedComments[actualGeneratedCommentIndex] = {
            ...copyOfGeneratedComments[actualGeneratedCommentIndex],
            comments: copyOfFeedbackParagraphsZerothIndexFeedback
        }

        copyOfComments.push({
            relevantText: feedbackItem.markedText,
            comment: feedbackItem.comment,
            startIndex: feedbackItem.startIndex,
            endIndex: feedbackItem.endIndex,
            authorName: auth.currentUser.displayName,
            authorEmail: auth.currentUser.email,
        })

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            comments: copyOfComments,
            generatedComments: copyOfGeneratedComments,
        })
    }

    const addToGeneralFeedback = async (feedbackItem) => {
        let currentGeneralFeedback = scan.generalFeedback ?? "";

        //Add text to generalFeedback
        if (scan.generalFeedback) currentGeneralFeedback += "\n\n";
        currentGeneralFeedback += feedbackItem.comment;


        //Update feedbackItem status
        const copyOfGeneratedComments = [...scan.generatedComments];

        const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfGeneratedComments[actualGeneratedCommentIndex].comments];
        copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
            ...feedbackItem,
            status: "added",
        }

        copyOfGeneratedComments[actualGeneratedCommentIndex] = {
            ...copyOfGeneratedComments[actualGeneratedCommentIndex],
            comments: copyOfFeedbackParagraphsZerothIndexFeedback
        }


        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            generalFeedback: currentGeneralFeedback,
            generatedComments: copyOfGeneratedComments,
        })
    }

    const ignoreFeedback = async (feedbackItem) => {
        const copyOfGeneratedComments = [...scan.generatedComments];

        //Modify specific feedbackItem status
        const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfGeneratedComments[actualGeneratedCommentIndex].comments];
        copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
            ...feedbackItem,
            status: "ignored",
        }

        //Add modified feedbackItem to feedback-array of feedbackParagraphs[0]
        copyOfGeneratedComments[actualGeneratedCommentIndex] = {
            ...copyOfGeneratedComments[actualGeneratedCommentIndex],
            comments: copyOfFeedbackParagraphsZerothIndexFeedback
        }

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
        await contentScanRef.update({
            generatedComments: copyOfGeneratedComments,
        })
    }

    //Find start- and endIndex of generated comments, add to array
    useEffect(() => {
        let fullFeedback = [];

        scan?.generatedComments[actualGeneratedCommentIndex].comments && scan.generatedComments[actualGeneratedCommentIndex].comments.forEach((generatedComment, index) => {
            const { startIndex, endIndex, found } = findTextIndices(scan.text, generatedComment.markedText);

            if (generatedComment.status !== "ignored") {
                fullFeedback.push({
                    ...generatedComment,
                    startIndex: found ? startIndex : null,
                    endIndex: found ? endIndex : null,
                    index: index,
                })
            }
        })

        const specificFeedback = fullFeedback.filter((fullFeedbackItem) => fullFeedbackItem.startIndex !== null && fullFeedbackItem.endIndex !== null);
        setUnspecificFeedback(fullFeedback.filter((fullFeedbackItem) => fullFeedbackItem.startIndex === null || fullFeedbackItem.endIndex === null) ?? []);

        setSortedSpecificFeedback(specificFeedback.sort((a, b) => a.startIndex - b.startIndex))
    }, [scan, actualGeneratedCommentIndex])

    //Add highlights to text for generated comments
    useEffect(() => {
        const originalText = scan.text;

        // Now, when rendering, you account for the possible displacement caused by earlier comments
        let currentOffset = 0;
        const elements = sortedSpecificFeedback.reduce((acc, comment) => {
            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, comment.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment itself
            acc.push(
                <mark
                    key={comment.startIndex}
                    data-feedback-index={comment.index}
                    onMouseEnter={() => setIndexOfHoveredSentence(comment.index)}
                    onMouseLeave={() => setIndexOfHoveredSentence(null)}
                    className={indexOfHoveredSentence == comment.index ? style.markFocused : style.mark}
                >
                    {originalText.slice(comment.startIndex, comment.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = comment.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);


    }, [sortedSpecificFeedback, setIndexOfHoveredSentence, indexOfHoveredSentence]);

    const isInSidebarView = useMediaQuery('(min-width: 1334px)');

    //Add comments based of <mark> tags in text
    useEffect(() => {
        const newCommentElements = [];
        const commentPositions = [];

        if (preRef.current) {
            const marks = preRef.current.querySelectorAll('mark');

            marks.forEach((mark, index) => {
                const feedbackIndex = mark.getAttribute('data-feedback-index');
                const feedbackItem = sortedSpecificFeedback.find((item) => item.index == feedbackIndex);

                const position = mark.getBoundingClientRect();

                if (feedbackItem) {
                    // Determine initial top position
                    const TOP_OFFSET = isInSidebarView ? 230 : 280;

                    let topPosition = (position.top + scrollPosition) > TOP_OFFSET ? (position.top + scrollPosition) - TOP_OFFSET : 0;

                    // Create an off-screen div to measure the comment height
                    const offscreenDiv = document.createElement('div');
                    offscreenDiv.className = style.comment;
                    offscreenDiv.style.fontSize = "14px";
                    offscreenDiv.style.padding = " 0.25rem 0.5rem";
                    offscreenDiv.style.position = "absolute";
                    offscreenDiv.style.left = "-9999px";  // position off-screen
                    offscreenDiv.innerText = feedbackItem.comment;
                    document.body.appendChild(offscreenDiv);

                    const getHeightOfReferences = () => {
                        if (!feedbackItem.sources) return 50;
                        return 100 + (feedbackItem.sources.length * 30)
                    }

                    // Measure the comment height
                    const commentHeight = offscreenDiv.offsetHeight + getHeightOfReferences();

                    // Remove the off-screen div
                    document.body.removeChild(offscreenDiv);

                    // Check for overlaps and adjust if necessary
                    for (const existingPosition of commentPositions) {
                        if ((topPosition >= existingPosition.start && topPosition <= existingPosition.end) ||
                            (topPosition + commentHeight >= existingPosition.start && topPosition <= existingPosition.end)) {
                            topPosition = existingPosition.end + 5;  // adjust by 5px gap
                        }
                    }

                    // Store the finalized position and height
                    commentPositions.push({ start: topPosition, end: topPosition + commentHeight });

                    // Create the JSX element for the comment
                    const commentElement = (
                        <div
                            key={index}
                            style={{
                                position: "absolute",
                                top: `${topPosition}px`,
                            }}
                            className={indexOfHoveredSentence == feedbackIndex ? style.commentFocused : style.comment}
                            onMouseEnter={() => setIndexOfHoveredSentence(feedbackIndex)}
                            onMouseLeave={() => setIndexOfHoveredSentence(null)}
                        >
                            <div className={style.commentContent}>
                                <p style={{ fontSize: "14px" }}>{feedbackItem.comment}</p>

                                {feedbackItem.sources && feedbackItem.sources.length > 0 && <div className={style.commentSourceWrapper}>
                                    <Text fz="sm" >{t('scan.feedback.sources')}</Text>
                                    {feedbackItem.sources.map((source, index) => <div className={style.commentSource}>
                                        <a target="_blank" rel="noreferrer" className={style.commentLink} href={source.link} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${formatSource(source.htmlTitle)}` }}></a>
                                    </div>)}
                                </div>}
                            </div>

                            {feedbackItem.status === "added" ?
                                <div className={style.commentAddedText}>
                                    <Flex columnGap={"0.3rem"} align={"center"}>
                                        <img className={style.checkmark} src={checkmark} alt=""></img>
                                        <span style={{ fontSize: "14px" }}>{t('scan.feedback.added-as-comment')}</span>
                                    </Flex>
                                </div>
                                :
                                <div className={style.commentButtonRow}>
                                    <UnstyledButton onClick={() => addFeedbackAsComment(feedbackItem)} className="plausible-event-name=addFeedback">
                                        <div className={style.commentButtonAdd}>{`+ ${t('scan.feedback.add-as-comment')}`}</div>
                                    </UnstyledButton>
                                    <UnstyledButton onClick={() => ignoreFeedback(feedbackItem)} className="plausible-event-name=ignoreFeedback">
                                        <div className={style.commentButtonIgnore}>{t('scan.feedback.ignore')}</div>
                                    </UnstyledButton>
                                </div>}
                        </div>
                    );

                    newCommentElements.push(commentElement);
                }

            });
        }

        setCommentElements(newCommentElements);
    }, [elements, indexOfHoveredSentence]);

    return (
        <div>
            <div>
                <div className={style.wrapper}>
                    <div
                        id="text-container"
                        className={style.text}
                        ref={preRef}
                        style={{ whiteSpace: 'pre-line' }}
                    >
                        {elements}
                    </div>

                    <div className={style.sideWrapper} >
                        <div className={style.commentListWrapper}>
                            <div className={style.commentList}>
                                {commentElements}
                            </div>
                        </div>
                    </div>
                </div>

                {unspecificFeedback.length > 0 && <div className={style.wrapperGeneralFeedback}>
                    <Divider mt={"4rem"} />
                    <h4>{t('scan.feedback.general-suggestions')}</h4>

                    {unspecificFeedback.map((feedbackItem) => (
                        <div className={style.generalFeedback}>
                            <Grid gutter={"2rem"}>
                                <Grid.Col span={9}>
                                    <Text>{feedbackItem.comment}</Text>

                                    {feedbackItem.sources && feedbackItem.sources.length > 0 && <div className={style.commentSourceWrapper}>
                                        <Text fz="sm" >{t('scan.feedback.sources')}</Text>
                                        {feedbackItem.sources.map((source, index) => <div className={style.commentSource}>
                                            <a target="_blank" rel="noreferrer" className={style.commentLink} href={source.link} dangerouslySetInnerHTML={{ __html: `${index + 1}. ${formatSource(source.htmlTitle)}` }}></a>
                                        </div>)}
                                    </div>}
                                </Grid.Col>

                                <Grid.Col span={3}>
                                    {feedbackItem.status === "added" ?
                                        <Flex columnGap={"0.3rem"} align={"center"} w={"15rem"}>
                                            <img className={style.checkmark} src={checkmark} alt=""></img>
                                            <span>{t('scan.feedback.added')}</span>
                                        </Flex>
                                        :
                                        <Flex direction={"column"} rowGap={"0.5rem"}>
                                            <Button onClick={() => addToGeneralFeedback(feedbackItem)} color="teal" className="plausible-event-name=addFeedback">{`+ ${t('common.add')}`}</Button>
                                            <Button onClick={() => ignoreFeedback(feedbackItem)} color="gray" variant="light" className="plausible-event-name=ignoreFeedback">{t('scan.feedback.ignore')}</Button>
                                        </Flex>}

                                </Grid.Col>
                            </Grid>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    )
}

export default GeneratedCommentTab;


const formatSource = (string) => {
    return string.replace(/<\/?b>/g, '');
}

function findTextIndices(fullText, searchText) {
    const startIndex = fullText.indexOf(searchText);
    const endIndex = startIndex + searchText.length;

    if (startIndex === -1) {
        return { startIndex: -1, endIndex: -1, found: false };
    }

    return { startIndex, endIndex, found: true };
}

function isCommentCollision(newComment, commentsArray) {
    for (let comment of commentsArray) {
        if (newComment.startIndex < comment.endIndex && newComment.endIndex > comment.startIndex) {
            return true; // There is a collision
        }
    }
    return false;
}