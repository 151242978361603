import { Link, useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";

import style from "./scan.module.css";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Flex, Loader } from "@mantine/core";
import FollowUpQuestions from "./tabs/follow-up-questions/follow-up-questions";
import { useMediaQuery } from "@mantine/hooks";

import { useCollectionData } from "react-firebase-hooks/firestore";
import TechnicalTermsTab from "./tabs/technical-terms/technical-terms";
import CommentsTab from "./tabs/comments/comments";
import ExportScan from "../../organisms/export-scan/export-scan";
import FeedbackTab from "./tabs/feedback/feedback";
import AIResult from "./tabs/ai-result";
import FullPage from "../../molecules/page/fullpage";
import Sidebar from "./components/sidebar/sidebar";
import Highlights from "./tabs/highlights/highlights";
import GeneratedCommentTab from "./tabs/suggestedComments/suggestedComments";

import checkmark from "./../../assets/checkmark.svg";
import cross from "./../../assets/crossRed.svg";
import thunder from "./../../assets/thunder.svg";
import science from "./../../assets/science.svg";
import comment from "./../../assets/comment.svg";
import highlightIcon from "./../../assets/highlight.svg";

import { IconListSearch, IconPencil, IconTextSpellcheck, IconList, IconHighlight, IconSpeakerphone } from "@tabler/icons-react";
import Summary from "./tabs/summary/summary";
import TechnicalTermsUsage from "./tabs/technical-terms-usage/technical-terms-usage";
import ModesOfPersuasion from "./tabs/modes-of-persuasion/modes-of-persuasion";

const Scan = ({ firestore, firebase, auth }) => {
    const { t } = useTranslation(["common"]);
    const scrollWrapperRef = useRef(null);

    const id = window.location.href.split("/")[window.location.href.split("/").length - 1];
    const navigate = useNavigate();

    const [tab, setTabState] = useState("ai");
    const [highlightIndex, setHighlightIndex] = useState(0);
    const [generatedCommentIndex, setGeneratedCommentIndex] = useState(0);

    const [scan, setScan] = useState(null);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [numberOfFeedbacks, setNumberOfFeedbacks] = useState(null);

    const contentScanRef = firestore.collection("content-scan");
    const query = contentScanRef.where(firebase.firestore.FieldPath.documentId(), "==", id);

    const [contentScans, loading] = useCollectionData(query, { idField: "id" });

    const setTab = (newTab) => {
        setTabState(newTab);

        if (scrollWrapperRef.current) {
            scrollWrapperRef.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        if (contentScans && contentScans.length > 0) {
            setScan(contentScans[0]);
            updateFeedbackState(contentScans[0]);
        }
    }, [contentScans]);

    const updateFeedbackState = (scan) => {
        if (!scan) return;

        let feedbackLoadingState = true;
        if (scan.textAnalysisInProgress === false) feedbackLoadingState = false;
        else if (scan.feedbackParagraphs === null) feedbackLoadingState = false;
        else if (scan.feedbackParagraphs && scan.expectedNumberOfFeedbackItems && scan.feedbackParagraphs?.length === scan.expectedNumberOfFeedbackItems)
            feedbackLoadingState = false;
        setFeedbackLoading(feedbackLoadingState);

        //Get number of feedback comments
        if (!feedbackLoadingState) {
            let numberOfFeedbacks = 0;

            scan.feedbackParagraphs?.forEach((feedbackParagraph) => {
                numberOfFeedbacks += feedbackParagraph.feedback?.length ?? 0;
            });

            setNumberOfFeedbacks(numberOfFeedbacks);
        }
    };

    const isDesktopOrTablet = useMediaQuery("(min-width: 450px)");
    const displayAllTabs = useMediaQuery("(min-width: 800px)");

    const numberOfFeedback = scan?.feedbackParagraphs
        ? scan?.feedbackParagraphs[0]?.feedback?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")?.length ?? null
        : null;

    const numberOfFactCheckComments = scan?.factCheckComments
        ? scan?.factCheckComments?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")?.length
        : scan?.feedbackParagraphs
        ? scan?.feedbackParagraphs[0]?.factCheckComments?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")
              ?.length ?? null
        : null;

    const numberOfLanguageCheckComments = scan?.languageCheckComments
        ? scan?.languageCheckComments?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")?.length
        : scan?.feedbackParagraphs
        ? scan?.feedbackParagraphs[0]?.languageCheckComments?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")
              ?.length ?? null
        : null;

    const numberOfLanguageImprovementComments = scan?.languageImprovementComments
        ? scan?.languageImprovementComments?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")?.length
        : scan?.feedbackParagraphs
        ? scan?.feedbackParagraphs[0]?.languageImprovementComments?.filter(
              (feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added"
          )?.length ?? null
        : null;

    const tabs = scan
        ? [
              {
                  name: "ai",
                  title: t("scan.result.tab-title"),
                  dependencyStatement: true,
                  component: <AIResult firebase={firebase} scan={scan} isDesktopOrTablet={isDesktopOrTablet} setTab={setTab} showFollowUpQuestionsAction />,
                  right: null,
                  icon: <img src={scan.containsAI ? cross : checkmark} style={{ width: "1rem", height: "0.75rem" }} />,
                  number: null,
              },
              {
                  name: "technicalTerms",
                  title: t("scan.terms.tab-title"),
                  dependencyStatement: scan.technicalTerms && scan.technicalTerms.length > 0,
                  component: <TechnicalTermsTab scan={scan} />,
                  right: null,
                  icon: null,
                  number: null,
              },
              {
                  name: "feedback", //For backwards compatiability (deprecated 08.03.2025)
                  title: t("scan.feedback.tab-title"),
                  dependencyStatement: false,
                  //   dependencyStatement:
                  //       scan.feedbackParagraphs &&
                  //       !(scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.factCheckComments) &&
                  //       !(scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.languageCheckComments) &&
                  //       !(scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.languageImprovementComments),
                  component: <FeedbackTab scan={scan} auth={auth} firestore={firestore} contentScanId={id} feedbackLoading={feedbackLoading} />,
                  right: feedbackLoading ? (
                      <Loader size={"xs"} />
                  ) : (
                      <div> {numberOfFeedback > 0 ? <Badge variant="filled">{numberOfFeedback}</Badge> : <span />} </div>
                  ),
                  icon: feedbackLoading ? <Loader size={"xs"} /> : <img src={science} style={{ width: "1rem", height: "1rem" }} />,
                  number: numberOfFeedbacks,
              },
              {
                  name: "factCheck",
                  title: t("scan.feedback.fact-check"),
                  dependencyStatement: scan.feedbackParagraphs, //scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.factCheckComments,
                  component: (
                      <FeedbackTab
                          scan={scan}
                          auth={auth}
                          firestore={firestore}
                          contentScanId={id}
                          feedbackLoading={feedbackLoading}
                          feedbackType={"factCheck"}
                      />
                  ),
                  right:
                      scan.textAnalysisInProgress && !numberOfFactCheckComments ? (
                          <Loader size={"xs"} />
                      ) : (
                          <div> {numberOfFactCheckComments > 0 ? <Badge variant="filled">{numberOfFactCheckComments}</Badge> : <span />} </div>
                      ),
                  icon: scan.textAnalysisInProgress && !numberOfFactCheckComments ? <Loader size={"xs"} /> : <IconListSearch size={"1rem"} />,
                  number: numberOfFactCheckComments,
                  disabled: feedbackLoading || !numberOfFactCheckComments || numberOfFactCheckComments === 0,
                  analyticsId: "tab-fact-check"
              },
              {
                  name: "languageCheck",
                  title: t("scan.feedback.language-check"),
                  dependencyStatement: scan.feedbackParagraphs, //scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.languageCheckComments,
                  component: (
                      <FeedbackTab
                          scan={scan}
                          auth={auth}
                          firestore={firestore}
                          contentScanId={id}
                          feedbackLoading={feedbackLoading}
                          feedbackType={"languageCheck"}
                      />
                  ),
                  right:
                      scan.textAnalysisInProgress && !numberOfLanguageCheckComments ? (
                          <Loader size={"xs"} />
                      ) : (
                          <div> {numberOfLanguageCheckComments > 0 ? <Badge variant="filled">{numberOfLanguageCheckComments}</Badge> : <span />} </div>
                      ),
                  icon: scan.textAnalysisInProgress && !numberOfLanguageCheckComments ? <Loader size={"xs"} /> : <IconTextSpellcheck size={"1rem"} />,
                  number: numberOfLanguageCheckComments,
                  disabled: feedbackLoading || !numberOfLanguageCheckComments || numberOfLanguageCheckComments === 0,
                  analyticsId: "tab-language-check"
              },
              {
                  name: "languageImprovements",
                  title: t("scan.feedback.language-improvements"),
                  dependencyStatement: scan.feedbackParagraphs, //scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.languageImprovementComments,
                  component: (
                      <FeedbackTab
                          scan={scan}
                          auth={auth}
                          firestore={firestore}
                          contentScanId={id}
                          feedbackLoading={feedbackLoading}
                          feedbackType={"languageImprovements"}
                      />
                  ),
                  right:
                      scan.textAnalysisInProgress && !numberOfLanguageImprovementComments ? (
                          <Loader size={"xs"} />
                      ) : (
                          <div>
                              {" "}
                              {numberOfLanguageImprovementComments > 0 ? <Badge variant="filled">{numberOfLanguageImprovementComments}</Badge> : <span />}{" "}
                          </div>
                      ),
                  icon: scan.textAnalysisInProgress && !numberOfLanguageImprovementComments ? <Loader size={"xs"} /> : <IconPencil size={"1rem"} />,
                  number: numberOfLanguageImprovementComments,
                  disabled: feedbackLoading || !numberOfLanguageImprovementComments || numberOfLanguageImprovementComments === 0,
                  analyticsId: "tab-language-improvements"
              },
              {
                  type: "divider",
                  disabled: !scan.feedbackParagraphs,
              },
              {
                  name: "followUpQuestions",
                  title: t("scan.fuq.tab-title"),
                  dependencyStatement: true, //scan.followUpQuestions,
                  component: <FollowUpQuestions scan={scan} firebase={firebase} firestore={firestore} contentScanId={id} />,
                  right: null,
                  icon: <img src={thunder} style={{ width: "1rem", height: "1.25rem" }} />,
                  number: null,
              },
              {
                  name: "summary",
                  title: t("scan.feedback.summary"),
                  dependencyStatement: scan.feedbackParagraphs, //scan.summary,
                  component: <Summary summary={scan.summary ?? scan.feedbackParagraphs ? scan.feedbackParagraphs[0]?.summary : null} />,
                  right: null,
                  icon: scan.textAnalysisInProgress && !scan.summary ? <Loader size={"xs"} /> : <IconList size={"1rem"} />,
                  number: null,
                  disabled: feedbackLoading || !scan.feedbackParagraphs || (!scan.feedbackParagraphs[0]?.summary && !scan.summary),
                  analyticsId: "tab-summary"
              },
              {
                  name: "technicalTermsUsage",
                  title: t("scan.feedback.technical-terms"),
                  dependencyStatement:
                      scan.technicalTermsUsage?.length > 0 || (scan.feedbackParagraphs && scan.feedbackParagraphs[0]?.technicalTerms?.length > 0),
                  component: (
                      <TechnicalTermsUsage
                          scan={scan}
                          technicalTerms={scan.technicalTermsUsage ?? scan.feedbackParagraphs ? scan.feedbackParagraphs[0]?.technicalTerms : []}
                      />
                  ),
                  right: null,
                  icon: <IconHighlight size={"1rem"} />,
                  number: null,
                  disabled:
                      feedbackLoading ||
                      ((!scan.technicalTermsUsage || scan.technicalTermsUsage?.length === 0) &&
                          (!scan.feedbackParagraphs || !scan.feedbackParagraphs[0]?.technicalTerms?.length > 0)),
                  analyticsId: "tab-technical-terms-usage"
              },
              {
                  name: "modesOfPersuasion",
                  title: t("scan.feedback.modes-of-persuasion.title"),
                  dependencyStatement: scan.modesOfPersuasion?.length > 0,
                  component: <ModesOfPersuasion scan={scan} modesOfPersuasion={scan.modesOfPersuasion ?? []} />,
                  right: null,
                  icon: <IconSpeakerphone size={"1rem"} />,
                  number: null,
                  disabled: feedbackLoading || !scan.modesOfPersuasion || scan.modesOfPersuasion?.length === 0,
                  analyticsId: "tab-modes-of-persuasion"
              },
              //   {
              //       name: "comments",
              //       title: t("scan.comments.tab-title"),
              //       dependencyStatement: scan.comments,
              //       component: <CommentsTab scan={scan} auth={auth} firestore={firestore} contentScanId={id} />,
              //       right: numberOfComments > 0 ? <Badge variant="outline">{numberOfComments}</Badge> : null,
              //       icon: <img src={comment} style={{ width: "1rem", height: "0.9rem" }} />,
              //       number: null,
              //   },
          ]
        : [];

    if (loading)
        return (
            <Page>
                <div style={{ width: "fit-content" }}>
                    <IconButton text={`← ${t("common.back")}`} onClick={() => (scan.parentId ? navigate(`/scans/${scan.parentId}`) : navigate("/scans"))} />
                </div>

                <Flex justify={"center"} mt={"10rem"} w={"100%"}>
                    <Loader color="dark" size={"3rem"} />
                </Flex>
            </Page>
        );

    if (scan) {
        return (
            <FullPage>
                <Flex>
                    <div className={style.sidebarWrapper}>
                        <Sidebar
                            firebase={firebase}
                            scan={scan}
                            tabs={tabs}
                            tab={tab}
                            setTab={setTab}
                            highlightIndex={highlightIndex}
                            setHighlightIndex={setHighlightIndex}
                            generatedCommentIndex={generatedCommentIndex}
                            setGeneratedCommentIndex={setGeneratedCommentIndex}
                        />
                    </div>

                    <div className={style.scrollWrapper} ref={scrollWrapperRef}>
                        <div className={style.mainWrapper}>
                            <div className={style.mainContent}>
                                <div className={style.backButtonWrapper} style={{ width: "fit-content" }}>
                                    <IconButton
                                        text={`← ${t("common.back")}`}
                                        onClick={() => (scan.parentId ? navigate(`/scans/${scan.parentId}`) : navigate("/scans"))}
                                    />
                                </div>

                                <div className={style.internalWrapper}>
                                    <div className={style.infoGroup}>
                                        <h3>{extractFirstWords(`${scan.name}`, 80)}</h3>
                                        <p className={style.date}>{new Date(scan.timestampFinished?.seconds * 1000 ?? 0).toLocaleDateString()}</p>
                                    </div>

                                    {displayAllTabs && (
                                        <div className={style.tabFill}>
                                            <Flex className={style.tabFillFlex}>
                                                <span className={`${style.tab} ${tab === "ai" ? style.tabClicked : ""}`} onClick={() => setTab("ai")}>
                                                    {t("scan.result.tab-title")}
                                                </span>
                                                {scan.followUpQuestions && (
                                                    <span
                                                        className={`${style.tab} ${tab === "followUpQuestions" ? style.tabClicked : ""}`}
                                                        onClick={() => setTab("followUpQuestions")}
                                                    >
                                                        {t("scan.fuq.tab-title")}
                                                    </span>
                                                )}
                                                {/* {scan.technicalTerms && scan.technicalTerms.length > 0 && (
                                                <span
                                                    className={`${style.tab} ${tab === "technicalTerms" ? style.tabClicked : ""}`}
                                                    onClick={() => setTab("technicalTerms")}
                                                >
                                                    {t("scan.terms.tab-title")}
                                                </span>
                                            )} */}
                                                {scan.feedbackParagraphs && numberOfFeedback > 0 && (
                                                    <Flex
                                                        align={"center"}
                                                        columnGap={"0.3rem"}
                                                        className={`${style.tab} ${tab === "feedback" ? style.tabClicked : ""}`}
                                                        onClick={() => setTab("feedback")}
                                                    >
                                                        {feedbackLoading ? (
                                                            <Loader size={"xs"} />
                                                        ) : (
                                                            <div>{numberOfFeedback > 0 ? <Badge variant="filled">{numberOfFeedback}</Badge> : <span />}</div>
                                                        )}
                                                        <span>{t("scan.feedback.tab-title")}</span>
                                                    </Flex>
                                                )}
                                                {scan.feedbackParagraphs && numberOfFactCheckComments > 0 && (
                                                    <Flex
                                                        align={"center"}
                                                        columnGap={"0.3rem"}
                                                        className={`${style.tab} ${tab === "factCheck" ? style.tabClicked : ""}`}
                                                        onClick={() => setTab("factCheck")}
                                                    >
                                                        {feedbackLoading ? (
                                                            <Loader size={"xs"} />
                                                        ) : (
                                                            <div>
                                                                {numberOfFactCheckComments > 0 ? (
                                                                    <Badge variant="filled">{numberOfFactCheckComments}</Badge>
                                                                ) : (
                                                                    <span />
                                                                )}
                                                            </div>
                                                        )}
                                                        <span>{t("scan.feedback.fact-check")}</span>
                                                    </Flex>
                                                )}
                                                {scan.feedbackParagraphs && numberOfLanguageCheckComments > 0 && (
                                                    <Flex
                                                        align={"center"}
                                                        columnGap={"0.3rem"}
                                                        className={`${style.tab} ${tab === "languageCheck" ? style.tabClicked : ""}`}
                                                        onClick={() => setTab("languageCheck")}
                                                    >
                                                        {feedbackLoading ? (
                                                            <Loader size={"xs"} />
                                                        ) : (
                                                            <div>
                                                                {numberOfLanguageCheckComments > 0 ? (
                                                                    <Badge variant="filled">{numberOfLanguageCheckComments}</Badge>
                                                                ) : (
                                                                    <span />
                                                                )}
                                                            </div>
                                                        )}
                                                        <span>{t("scan.feedback.language-check")}</span>
                                                    </Flex>
                                                )}
                                                {/* {scan.comments && <span className={`${style.tab} ${tab === "comments" ? style.tabClicked : ""}`} onClick={() => setTab("comments")}>Legg til kommentarer</span>} */}

                                                {/* {scan.comments && (
                                                <Flex
                                                    align={"center"}
                                                    columnGap={"0.3rem"}
                                                    className={`${style.tab} ${tab === "comments" ? style.tabClicked : ""}`}
                                                    onClick={() => setTab("comments")}
                                                >
                                                    <div>{numberOfComments > 0 ? <Badge variant="outline">{numberOfComments}</Badge> : <span />}</div>
                                                    <span>{t("scan.comments.tab-title")}</span>
                                                </Flex>
                                            )} */}
                                            </Flex>

                                            {tab === "comments" ? (
                                                <div className={style.exportButtonWrapper}>
                                                    <ExportScan scan={scan} firebase={firebase} firestore={firestore} contentScanId={id} />
                                                </div>
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                    )}

                                    {/* Tabs for Mobil */}
                                    {!displayAllTabs && (
                                        <>
                                            <br />
                                            <div className={style.tabFill}>
                                                <Flex className={style.tabFillFlex}>
                                                    <span className={`${style.tab} ${tab === "ai" ? style.tabClicked : ""}`} onClick={() => setTab("ai")}>
                                                        {t("scan.result.tab-title")}
                                                    </span>
                                                    {scan.followUpQuestions && (
                                                        <span
                                                            className={`${style.tab} ${tab === "followUpQuestions" ? style.tabClicked : ""}`}
                                                            onClick={() => setTab("followUpQuestions")}
                                                        >
                                                            {t("scan.fuq.tab-title")}
                                                        </span>
                                                    )}
                                                </Flex>
                                            </div>
                                        </>
                                    )}

                                    <br />

                                    {tab === "ai" && (
                                        <AIResult
                                            firebase={firebase}
                                            scan={scan}
                                            contentScanId={id}
                                            isDesktopOrTablet={isDesktopOrTablet}
                                            setTab={setTab}
                                            showFollowUpQuestionsAction
                                        />
                                    )}

                                    {tab === "feedback" && (
                                        <FeedbackTab
                                            scan={scan}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            feedbackLoading={feedbackLoading}
                                            scrollWrapperRef={scrollWrapperRef}
                                        />
                                    )}

                                    {tab === "factCheck" && (
                                        <FeedbackTab
                                            scan={scan}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            feedbackLoading={feedbackLoading}
                                            scrollWrapperRef={scrollWrapperRef}
                                            feedbackType={"factCheck"}
                                        />
                                    )}

                                    {tab === "languageCheck" && (
                                        <FeedbackTab
                                            scan={scan}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            feedbackLoading={feedbackLoading}
                                            scrollWrapperRef={scrollWrapperRef}
                                            feedbackType={"languageCheck"}
                                        />
                                    )}

                                    {tab === "languageImprovements" && (
                                        <FeedbackTab
                                            scan={scan}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            feedbackLoading={feedbackLoading}
                                            scrollWrapperRef={scrollWrapperRef}
                                            feedbackType={"languageImprovements"}
                                        />
                                    )}

                                    {tab === "followUpQuestions" && (
                                        <FollowUpQuestions scan={scan} firebase={firebase} firestore={firestore} contentScanId={id} />
                                    )}

                                    {tab === "summary" && (
                                        <Summary summary={scan.summary ? scan.summary : scan.feedbackParagraphs ? scan.feedbackParagraphs[0]?.summary : null} />
                                    )}

                                    {tab === "technicalTermsUsage" && (
                                        <TechnicalTermsUsage
                                            scan={scan}
                                            technicalTerms={
                                                scan.technicalTermsUsage
                                                    ? scan.technicalTermsUsage
                                                    : scan.feedbackParagraphs
                                                    ? scan.feedbackParagraphs[0]?.technicalTerms
                                                    : []
                                            }
                                        />
                                    )}

                                    {tab === "modesOfPersuasion" && <ModesOfPersuasion scan={scan} modesOfPersuasion={scan.modesOfPersuasion ?? []} />}

                                    {tab === "technicalTerms" && <TechnicalTermsTab scan={scan} />}

                                    {tab === "comments" && (
                                        <CommentsTab
                                            scan={scan}
                                            firebase={firebase}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            scrollWrapperRef={scrollWrapperRef}
                                        />
                                    )}

                                    {tab === "highlights" && <Highlights scan={scan} highlightIndex={highlightIndex} />}

                                    {tab === "generatedComments" && (
                                        <GeneratedCommentTab
                                            scan={scan}
                                            auth={auth}
                                            firestore={firestore}
                                            contentScanId={id}
                                            generatedCommentIndex={generatedCommentIndex}
                                            scrollWrapperRef={scrollWrapperRef}
                                        />
                                    )}
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </Flex>
            </FullPage>
        );
    }

    return (
        <Page>
            <div style={{ width: "fit-content" }}>
                <IconButton text={`← ${t("common.back")}`} onClick={() => (scan.parentId ? navigate(`/scans/${scan.parentId}`) : navigate("/scans"))} />
            </div>

            <p>{t("scan.not-found")}</p>
        </Page>
    );
};

export default Scan;

const extractFirstWords = (text, maxChars) => {
    const length = text.split("").length;
    if (length < maxChars) return text;

    let extractedText = text.substring(0, maxChars);
    const lastSpace = extractedText.lastIndexOf(" ") + 1;
    extractedText = extractedText.substring(0, lastSpace) + "...";
    return extractedText;
};
