import firebase from "firebase/compat/app";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";
import GoogleButton from "react-google-button";

import style from "./createUserForm.module.css";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateUserForm = ({ auth, nextPageUrl }) => {
    const { t } = useTranslation(["common"]);

    const navigate = useNavigate();

    const isMetaBrowser = navigator.userAgent.match(/FBAN|FBAV/i); //True if browser is Facebook or Messenger app browser

    const [newEmail, setNewEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [error, setError] = useState("");

    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        auth.signInWithPopup(provider);

        setPersistence();
        navigate(nextPageUrl);
    };

    const signUpWithEmail = () => {
        setError("");

        firebase
            .auth()
            .createUserWithEmailAndPassword(newEmail, newPassword)
            .then((userCredential) => {
                // Signed in
                //var user = userCredential.user;
                // ...
                console.log("Bruker opprettet.");
                // toast.success("Bruker opprettet. Logger deg inn...", {
                //     duration: 3000,
                //     position: 'bottom-center',
                // })
            })
            .catch((error) => {
                setErrorMessage(error);
            });

        setPersistence();

        navigate(nextPageUrl);

        // if (newEmail.toLowerCase().endsWith('.no')) {
        //     navigate(nextPageUrl)
        // } else {
        //     navigate("/waitlist")
        // }
    };

    const setPersistence = async () => {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    };

    const setErrorMessage = (error) => {
        if (error.code === "auth/email-already-exists") setError(t("auth.errors.email-already-exists"));
        else if (error.code === "auth/user-not-found") setError(t("auth.errors.user-not-found"));
        else if (error.code === "auth/email-already-in-use") setError(t("auth.errors.email-already-exists"));
        else if (error.code === "auth/wrong-password") setError(t("auth.errors.wrong-password"));
        else if (error.code === "auth/weak-password") setError(t("auth.errors.weak-password"));
        else setError(error.message);
    };

    const validateEmail = (email) => {
        // Split the email into two parts: the username and domain
        const emailParts = email.split("@");
        if (emailParts.length !== 2) {
            return false; // email should have only one @ symbol
        }

        const username = emailParts[0];
        const domain = emailParts[1];

        // Check if the domain has a valid format
        const domainParts = domain.split(".");
        if (domainParts.length < 2) {
            return false; // domain should have at least one dot
        }

        for (let i = 0; i < domainParts.length; i++) {
            if (domainParts[i].length < 2) {
                return false; // each domain part should have at least two characters
            }
        }

        // Check if the username has a valid format
        if (username.length < 1) {
            return false; // username should not be empty
        }

        return true;
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && validateEmail(newEmail)) {
            signUpWithEmail();
        }
    };

    return (
        <div className={style.contentWrapper}>
            <h2>{t("auth.create-user.form.title")}</h2>
            <Link to="/sign-in">
                <p className={style.link}>{t("auth.create-user.form.switch-to-sign-in-link")}</p>
            </Link>
            <div className={style.signInGroup}>
                <input
                    autoFocus
                    type="email"
                    placeholder={t("auth.create-user.form.email-label")}
                    className="input"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                ></input>
                <input
                    type="password"
                    placeholder={t("auth.create-user.form.password-label")}
                    className="input"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                ></input>
                <input
                    type="password"
                    placeholder={t("auth.create-user.form.confirm-password-label")}
                    className="input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                ></input>

                <div
                    className={style.privacySubmitWrapper}
                    onClick={() => (privacyPolicyAccepted ? setPrivacyPolicyAccepted(false) : setPrivacyPolicyAccepted(true))}
                >
                    <input
                        checked={privacyPolicyAccepted}
                        onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
                        id="acceptPrivacyPolicy"
                        name="acceptPrivacyPolicy"
                        type="checkbox"
                    ></input>
                    <label>
                        {`${t("auth.create-user.form.privacy.accept")} `}
                        <a target="blank" href={t("auth.create-user.form.privacy.privacy-policy-link")}>
                            {t("auth.create-user.form.privacy.privacy-policy")}
                        </a>
                        {` ${t("auth.create-user.form.privacy.and")} `}
                        <a target="blank" href={t("auth.create-user.form.privacy.terms-and-conditions-link")}>
                            {t("auth.create-user.form.privacy.terms-and-conditions")}
                        </a>
                    </label>
                </div>

                <div className={style.signInSubmit}>
                    <p className={style.errorMessage}>{error}</p>
                    <IconButton
                        className="plausible-event-name=Signup"
                        primary
                        text={`${t("auth.create-user.form.submit")} →`}
                        onClick={() => signUpWithEmail()}
                        disabled={!privacyPolicyAccepted || !validateEmail(newEmail) || newPassword === "" || newPassword !== confirmPassword}
                    />
                </div>
            </div>

            <div className={style.contentGroup}>
                <div className={style.actionGroup}>
                    <div className={style.actionItem}>
                        <GoogleButton
                            className="plausible-event-name=Signup"
                            onClick={isMetaBrowser ? () => console.log("Not possible in Facebook or Messenger browser") : () => signInWithGoogle()}
                        />
                        <br />
                        {isMetaBrowser && <span style={{ fontSize: "14px" }}>{t("auth.meta-browser-warning")}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateUserForm;
