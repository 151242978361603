import React, { useContext, useEffect, useState } from 'react';
import { UserAndOrganzationContext } from '../contexts/userAndOrganizationContext';
import { useTranslation } from 'react-i18next';

const useGetSubscription = () => {
    const { t } = useTranslation(['common']);

    const [subscriptionType, setSubscriptionType] = useState(null);
    const [subscriptionName, setSubscriptionName] = useState(null);

    const [scanLimit, setScanLimit] = useState(0);
    const [wordLimit, setWordLimit] = useState(1000);

    const [isProfessional, setIsProfessional] = useState(false);
    const [isTrial, setIsTrial] = useState(true);

    const [subscriptionFromOrganization, setSubscriptionFromOrganization] = useState(false);


    const { user, organization, loading } = useContext(UserAndOrganzationContext);

    useEffect(() => {

        if (!user || (user?.userData?.organization && !organization?.organizationData)) return;

        const organizationSubscription = organization?.organizationData?.subscription;
        const userSubscription = user?.userData?.type;

        // console.log(organizationSubscription)
        // console.log(userSubscription)


        if (organizationSubscription) {
            setSubscriptionType(organizationSubscription);

            if (organizationSubscription === "subscription1") { //Premium for schools (deprecated)
                setScanLimit(120);
                setWordLimit(10000);
                setIsTrial(false);
                setIsProfessional(true);
                setSubscriptionName(t('navbar.pro'));
                setSubscriptionFromOrganization(true);
            }
            else if (organizationSubscription === "subscription-org1" || organizationSubscription === "subscription-org1-trial60") { //Premium for schools with trial
                setScanLimit(120);
                setWordLimit(10000);
                setIsTrial(false);
                setIsProfessional(true);
                setSubscriptionName(t('navbar.pro'));
                setSubscriptionFromOrganization(true);
            }

            else if (organizationSubscription === "waiting" || organizationSubscription === "cancelled") {
                setScanLimit(0);
                setSubscriptionFromOrganization(true);
            }
        }

        else if (userSubscription) {
            setSubscriptionType(userSubscription);

            if (userSubscription === "subscription0" || userSubscription === "subscription0-trial7") { //Premium
                setScanLimit(80);
                setWordLimit(1000);
                setIsTrial(false);
                setSubscriptionName(t('navbar.premium'));
            }
            // else if (user?.userData?.type && user.userData.type === "subscription0-trial7") {
            //     if (isDateMoreThan7DaysAgo(user.userData.subscribedTimestamp?.seconds * 1000 ?? null)) {
            //         setScanLimit(80);
            //         setWordLimit(1000);
            //         setIsTrial(false);
            //     } else {
            //         setScanLimit(20);
            //         setWordLimit(1000);
            //     }
            // }
            else if (userSubscription === "subscription1" || userSubscription === "subscription1-trial7") { //Professional
                setScanLimit(120);
                setWordLimit(10000);
                setIsTrial(false);
                setIsProfessional(true);
                setSubscriptionName(t('navbar.pro'));
            }
            // else if (user?.userData?.type && user.userData.type === "subscription1-trial7") {
            //     if (isDateMoreThan7DaysAgo(user.userData.subscribedTimestamp.seconds * 1000)) {
            //         setScanLimit(120);
            //         setWordLimit(2000);
            //         setIsTrial(false);
            //     } else {
            //         setScanLimit(20);
            //         setWordLimit(2000);
            //     }
            // }
            else if (userSubscription === "subscription2" || userSubscription === "subscription2-trial7") { //Flex
                setScanLimit(40);
                setWordLimit(1000);
                setIsTrial(false);
                setSubscriptionName(t('navbar.flex'));
            }
            else if (userSubscription === "trial") { //Trial
                setScanLimit(20);
                setWordLimit(1000);
                setIsTrial(true);
                setSubscriptionName(t('navbar.trial'));
            }
            else if (userSubscription === "waiting" || userSubscription === "cancelled") {
                setScanLimit(0);
            }
        }
    }, [user, organization])

    return {
        subscriptionType: subscriptionType,
        subscriptionName: subscriptionName,
        scanLimit: scanLimit,
        wordLimit: wordLimit,
        isProfessional: isProfessional,
        isTrial: isTrial,
        subscriptionFromOrganization: subscriptionFromOrganization,
    };
}

export default useGetSubscription;