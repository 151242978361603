import { useTranslation } from "react-i18next";
import style from './summary.module.css'

const Summary = ({ summary }) => {
    const { t } = useTranslation(["common"]);

    if (summary)
        return (
            <div className={style.wrapper}>
                <div className={style.titleWrapper}>
                    <h4>{t("scan.feedback.summary")}</h4>
                </div>

                <pre className={`${style.text}`}>{summary}</pre>
            </div>
        );

    return null;
};

export default Summary;
