import { Box, Button, Card, Flex, List, Space, Text, Title } from "@mantine/core";
import Page from "../../molecules/page/page"
import style from "./upgrade.module.css"
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useState } from "react";
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";
import { useTranslation } from "react-i18next";
import PricingLoader from "../../molecules/pricing-loader";
import { useNavigate } from "react-router-dom";
import { priceIdFlex, priceIdPremium, priceIdProfessional } from "../../contexts/priceIdVariables";
import SwitchSubscription from "./components/switch-subscription";
import useGetSubscription from "../../hooks/useGetSubscription";
import ManageSubscription from "./components/manage-subscription";

const Upgrade = ({ firebase, auth, firestore }) => {
    const { user, organization } = useContext(UserAndOrganzationContext);
    const { subscriptionFromOrganization } = useGetSubscription();
    const { t } = useTranslation(['common']);
    const navigate = useNavigate();

    const [loading0, setLoading0] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleLoadingState = (subscriptionNumber, newState) => {
        switch (subscriptionNumber) {
            case 0: setLoading0(newState); break;
            case 1: setLoading1(newState); break;
            case 2: setLoading2(newState); break;
        }
    }

    const redirectToCheckout = async (priceId, subscriptionNumber) => {
        handleLoadingState(subscriptionNumber, true)

        // Replace with your Stripe publishable key
        const stripe = await loadStripe('pk_live_51NTOXIKwREFQyFmXRZDU7ohPjdtDGzYxjN4rW7VsQRP5GUjFss8ljK9He5LAIkmcvFU7KfEYFai3QZWtKABUPhlu000FG2ds5E');

        const sessionId = await createCheckoutSession(priceId);

        if (sessionId) {
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error('Error redirecting to checkout:', error);
            }
        }

        handleLoadingState(subscriptionNumber, false)
    };

    const createCheckoutSession = async (priceId) => {
        const functions = firebase.app().functions("europe-west1");
        const createCheckoutSessionQuery = functions.httpsCallable('checkoutSession');
        const hostname = window.location.hostname;

        try {
            const result = await createCheckoutSessionQuery({ priceId, isTrial: false, hostname });
            return result.data.sessionId;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <Page grey>

            <PricingLoader opened={loading0 || loading1 || loading2} text={t("upgrade.loading-label-pay")} />

            <div className={style.wrapper}>

                <h2 style={{ width: "100%", textAlign: "center" }}>{user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting") ? t("upgrade.choose-subscription") : t("upgrade.manage-subscription.title")}</h2>

                <div className={style.grid}>

                    {user?.userData?.type && (user.userData.type === "subscription0" || user.userData.type === "subscription0-trial7") ?
                        <Card shadow="md" className={style.gridItem}>
                            <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                                <div className={style.gridItemTopContent}>
                                    <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.premium.name")}</Text>
                                    <br />
                                    <List>
                                        <List.Item>{t("upgrade.premium.scan-limit")}</List.Item>
                                        <List.Item>{t("upgrade.premium.word-limit")}</List.Item>
                                        <List.Item>{t("upgrade.premium.ai-scan")}</List.Item>
                                        <List.Item>{t("upgrade.premium.follow-up-questions")}</List.Item>
                                    </List>
                                </div>

                                <br />
                                {user?.userData?.type && (user.userData.type !== "subscription0" && user.userData.type !== "subscription0-trial7") ?
                                    <>
                                        <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.premium.price")}</Text>
                                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.premium.billing-frequency")}</Text>
                                    </>
                                    :
                                    user?.userData?.willBeCancelledAtDate ?
                                    <Box py={"0.5rem"} px={"1rem"} bg={"rgb(235, 235, 235)"} style={{border: "1px solid rgb(220, 220, 220)"}}>
                                        <Text ta={"center"} fz={12} fw={"500"} color="rgb(80, 80, 80)">
                                            {`${t("upgrade.will-not-be-renewed")} ${new Date(user?.userData?.willBeCancelledAtDate.seconds * 1000 ?? 0).toLocaleDateString()}`}
                                        </Text>
                                    </Box>
                                    :
                                    <Space h={52} />
                                }

                                {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting" || user.userData.type === "cancelled") &&
                                    <Button color={"dark"} variant="outline" className={"plausible-event-name=buy1-premium"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout(priceIdPremium, 0)} loading={loading0}>{t("upgrade.buy-now")}</Button>
                                }

                                {user?.userData?.type && (user.userData.type === "subscription0" || user.userData.type === "subscription0-trial7") ?
                                    <ManageSubscription cancelDisabled={!!user?.userData?.willBeCancelledAtDate}/>
                                    :
                                    (user?.userData?.type && user.userData.type !== "trial" && user.userData.type !== "waiting" && user.userData.type !== "cancelled") &&
                                    <SwitchSubscription
                                        priceId={priceIdPremium}
                                        subscriptionNumber={0}
                                        loading={loading0}
                                        handleLoadingState={handleLoadingState}
                                    />
                                }
                            </Card.Section>
                        </Card>
                        :
                        <Card shadow="md" className={style.gridItem}>
                            <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                                <div className={style.gridItemTopContent}>
                                    <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.flex.name")}</Text>
                                    <br />
                                    <List>
                                        <List.Item>{t("upgrade.flex.scan-limit")}</List.Item>
                                        <List.Item>{t("upgrade.flex.word-limit")}</List.Item>
                                        <List.Item>{t("upgrade.flex.ai-scan")}</List.Item>
                                        <List.Item>{t("upgrade.flex.follow-up-questions")}</List.Item>
                                    </List>
                                </div>

                                <br />
                                {/* <Text fz={"1rem"} fw={"600"} underline>{t("upgrade.flex.price")}</Text> */}

                                {user?.userData?.type && (user.userData.type !== "subscription2" && user.userData.type !== "subscription2-trial7") ?
                                    <>
                                        <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.flex.price")}</Text>
                                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.flex.billing-frequency")}</Text>
                                    </>
                                    :
                                    user?.userData?.willBeCancelledAtDate ?
                                    <Box py={"0.5rem"} px={"1rem"} bg={"rgb(235, 235, 235)"} style={{border: "1px solid rgb(220, 220, 220)"}}>
                                        <Text ta={"center"} fz={12} fw={"500"} color="rgb(80, 80, 80)">
                                            {`${t("upgrade.will-not-be-renewed")} ${new Date(user?.userData?.willBeCancelledAtDate.seconds * 1000 ?? 0).toLocaleDateString()}`}
                                        </Text>
                                    </Box>
                                    :
                                    <Space h={52} />
                                }

                                {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting" || user.userData.type === "cancelled") &&
                                    <Button color={"dark"} variant="outline" className={"plausible-event-name=buy1-flex"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout(priceIdFlex, 2)} loading={loading2}>{t("upgrade.buy-now")}</Button>
                                }

                                {user?.userData?.type && (user.userData.type === "subscription2" || user.userData.type === "subscription2-trial7") ?
                                    <ManageSubscription cancelDisabled={!!user?.userData?.willBeCancelledAtDate}/>
                                    :
                                    (user?.userData?.type && user.userData.type !== "trial" && user.userData.type !== "waiting" && user.userData.type !== "cancelled") &&
                                    <SwitchSubscription
                                        priceId={priceIdFlex}
                                        subscriptionNumber={2}
                                        loading={loading2}
                                        handleLoadingState={handleLoadingState}
                                    />
                                }
                            </Card.Section>
                        </Card>
                    }

                    <Card shadow="md" className={style.gridItemHighlighted}>
                        <Card.Section bg={"dark"} py={"0.25rem"}>
                            <Flex justify={"center"}>
                                <Title fw={500} fz={"1rem"} c={"white"}>{t("upgrade.most-popular")}</Title>
                            </Flex>
                        </Card.Section>

                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.pro.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.pro.scan-limit")}</List.Item>
                                    <List.Item>{t("upgrade.pro.word-limit")}</List.Item>
                                    <List.Item>{t("upgrade.pro.ai-scan")}</List.Item>
                                    <List.Item>{t("upgrade.pro.follow-up-questions")}</List.Item>
                                    <List.Item>{t("upgrade.pro.fact-check")}</List.Item>
                                    <List.Item>{t("upgrade.pro.language-check")}</List.Item>
                                    {/* <List.Item>{t("upgrade.pro.copilot")}</List.Item> */}
                                    <List.Item>{t("upgrade.pro.text-analysis")}</List.Item>     
                                    <List.Item>{t("upgrade.pro.technical-terms")}</List.Item>                                  
                                </List>
                            </div>

                            <br />
                            {user?.userData?.type && (user.userData.type !== "subscription1" && user.userData.type !== "subscription1-trial7") ?
                                <>
                                    <Flex columnGap={"0.5rem"} align={"baseline"}>
                                        <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.pro.price")}</Text>
                                        {/* <Text color="red" style={{ textDecoration: "line-through" }} fz={"1.25rem"} fw={"500"}>{t("upgrade.pro.price")}</Text> */}
                                        {/* <Text fz={12} color="red">{`${daysUntil("2024-09-30")} ${t("upgrade.pro.days-left")}`}</Text> */}
                                    </Flex>
                                    <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.pro.billing-frequency")}</Text>
                                </>
                                :
                                user?.userData?.willBeCancelledAtDate ?
                                <Box py={"0.5rem"} px={"1rem"} bg={"rgb(235, 235, 235)"} style={{border: "1px solid rgb(220, 220, 220)"}}>
                                    <Text ta={"center"} fz={12} fw={"500"} color="rgb(80, 80, 80)">
                                        {`${t("upgrade.will-not-be-renewed")} ${new Date(user?.userData?.willBeCancelledAtDate.seconds * 1000 ?? 0).toLocaleDateString()}`}
                                    </Text>
                                </Box>
                                :
                                <Space h={52} />
                            }

                            {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting" || user.userData.type === "cancelled") &&
                                <Button color={"dark"} className={"plausible-event-name=buy1-full-package"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout(priceIdProfessional, 1)} loading={loading1}>{t("upgrade.buy-now")}</Button>
                            }
                            {user?.userData?.type && (user.userData.type === "subscription1" || user.userData.type === "subscription1-trial7") ?
                                <ManageSubscription cancelDisabled={!!user?.userData?.willBeCancelledAtDate}/>
                                :
                                (user?.userData?.type && user.userData.type !== "trial" && user.userData.type !== "waiting" && user.userData.type !== "cancelled") &&
                                <SwitchSubscription
                                    priceId={priceIdProfessional}
                                    subscriptionNumber={1}
                                    loading={loading1}
                                    handleLoadingState={handleLoadingState}
                                />
                            }
                        </Card.Section>
                    </Card>

                    <Card shadow="md" className={style.gridItem}>
                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.schools.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.schools.all-of-the-above")}</List.Item>
                                    <List.Item>{t("upgrade.schools.dpa")}</List.Item>
                                </List>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.schools.free-trial")}</List.Item>
                                    <List.Item>{t("upgrade.schools.multiple-users")}</List.Item>
                                </List>
                            </div>

                            <br />
                            {!organization?.organizationId ?
                                <>
                                    <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.schools.price")}</Text>
                                    <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.schools.billing-frequency")}</Text>
                                </>
                                :
                                <Space h={52} />
                            }

                            {organization?.organizationId ?
                                <Button variant="filled" color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => navigate("/organization")} >{t('navbar.organization')}</Button>
                                :
                                user?.userData?.type && !subscriptionFromOrganization ?
                                    <Button className={"plausible-event-name=trial-schools"} variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => navigate("/new-organization")} >{t("upgrade.schools.action-button")}</Button>
                                    :
                                    <Space h={52} />
                            }
                        </Card.Section>
                    </Card>
                </div>
            </div>
        </Page>
    )
}

export default Upgrade;

function daysUntil(date) {
    const today = new Date();
    const targetDate = new Date(date);

    // Calculate the difference in time
    const differenceInTime = targetDate.getTime() - today.getTime();

    // Calculate the difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
}
