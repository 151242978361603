import { Flex } from "@mantine/core";
import InfoSummary from "../../../molecules/infoSummary/infoSummary";
import { Link } from "react-router-dom";
import CreateFollowUpQuestionsButton from "../../../organisms/create-follow-up-questions/create-follow-up-questions";
import InfoSlabMobile from "../../../molecules/infoSlabMobile/infoSlabMobile";
import InfoSlab from "../../../molecules/infoSlab/infoSlab";

import style from './../scan.module.css'
import { useTranslation } from "react-i18next";


const AIResult = ({ scan, contentScanId, isDesktopOrTablet, setTab, firebase, showFollowUpQuestionsAction = false }) => {
    const { t } = useTranslation(['common']);

    return (
        <div>
            <InfoSummary scan={scan}>
                <Flex columnGap={"2rem"} rowGap={"1rem"} justify={"flex-end"} direction={isDesktopOrTablet ? "row" : "column"}>
                    <Link to="/home/#result_confidence"><p className={`${style.link} plausible-event-name=link-explain-ai-result`}>{t('scan.summary.explaination-link')}</p></Link>
                    {showFollowUpQuestionsAction && <>
                        {scan.followUpQuestions ?
                            <Link to="" onClick={() => setTab("followUpQuestions")}><p className={style.link}>{t('scan.fuq.link')}</p></Link>
                            :
                            <CreateFollowUpQuestionsButton firebase={firebase} text={scan.text} contentScanId={contentScanId} onFinished={() => setTab("followUpQuestions")} />
                        }
                    </>}
                </Flex>
            </InfoSummary>

            {scan.paragraphs.map((paragraph) => (
                <div key={`${paragraph.index}`} className={true ? style.paragraphWrapperAI : style.paragraphWrapper}>
                    <div className={style.resultBoxMobile}>
                        <InfoSlabMobile scan={scan} aiScore={paragraph.aiScore} />
                    </div>
                    {paragraph.aiScore >= 0.999 ?
                        <pre className={`${style.text}`}>
                            <mark className={style.mark}>{paragraph.originalText}</mark>
                        </pre>
                        : <pre className={`${style.text}`}>
                            {paragraph.originalText}
                        </pre>
                    }
                    <div className={style.resultBox}>
                        <InfoSlab scan={scan} aiScore={paragraph.aiScore} humanScore={paragraph.humanScore} />
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AIResult;