import { Link } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import toast from 'react-hot-toast';
import { useTranslation } from "react-i18next"
import { Badge, Flex, Tooltip } from "@mantine/core"

import optionIcon from '../../assets/optionIcon.svg'
import commentErrorIcon from '../../assets/commentError.svg'
import commentOutlinedIcon from '../../assets/commentOutlined.svg'
import downloadIcon from '../../assets/download.png'

import IconButton from "../IconButton/iconButton"
import CondensedResultSlab from "../condensedResultSlab/condensedResultSlab"
import Modal from "../modal/modal"

import style from "./scanListItem.module.css"


const ScanListItem = ({ scan, firestore, numberOfDeletedResults, setNumberOfDeletedResults }) => {
    const { t } = useTranslation(['common']);

    const [optionExpanded, setOptionExpanded] = useState(false)
    const [displayEdit, setDisplayEdit] = useState(false)

    const [name, setName] = useState(scan.name);

    const date = new Date(scan.timestampFinished?.seconds * 1000 ?? 0)
    const componentRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (componentRef.current && !componentRef.current.contains(e.target)) {
                setOptionExpanded(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [componentRef]);

    const updateName = () => {
        const documentRef = firestore.collection("content-scan").doc(scan.id);

        documentRef.update({ name: name }).then(() => {
            toast.success(t('scans.item.toast.rename.success'), {
                duration: 3000,
                position: 'bottom-center',
            })
        }).catch((error) => {
            toast.error(t('scans.item.toast.rename.error'), {
                duration: 4000,
                position: 'bottom-center',
            })
        })

        setDisplayEdit(false)

        const numberOfResultsBeforeDeletion = numberOfDeletedResults;
        setNumberOfDeletedResults(numberOfResultsBeforeDeletion + 1);
    }


    const deleteScan = () => {
        firestore.collection("content-scan").doc(scan.id).update({
            text: null,
            englishText: null,
            paragraphs: null,
            name: null,
            feedbackParagraphs: null,
            comments: null,
            deleted: true,
        }).then(() => {
            toast.success(t('scans.item.toast.delete.success'), {
                duration: 3000,
                position: 'bottom-center',
            })
        }).catch((error) => {
            toast.error(t('scans.item.toast.delete.error'), {
                duration: 4000,
                position: 'bottom-center',
            })
        });

        const numberOfResultsBeforeDeletion = numberOfDeletedResults;
        setNumberOfDeletedResults(numberOfResultsBeforeDeletion + 1);
    }

    // const numberOfFeedback = scan?.feedbackParagraphs ? scan?.feedbackParagraphs[0]?.feedback?.filter((feedbackItem) => feedbackItem.status !== "ignored" && feedbackItem.status !== "added")?.length ?? 0 : 0;
    // const numberOfComments = scan?.comments ? scan.comments.length : 0;

    const numberOfFactCheckComments = scan?.factCheckComments ? scan.factCheckComments?.length : scan?.feedbackParagraphs ? scan?.feedbackParagraphs[0]?.factCheckComments?.length ?? 0 : 0;
    const numberOfLanguageCheckComments = scan?.languageCheckComments ? scan.languageCheckComments?.length : scan?.feedbackParagraphs ? scan?.feedbackParagraphs[0]?.languageCheckComments?.length ?? 0 : 0;

    const numberOfErrors = numberOfFactCheckComments + numberOfLanguageCheckComments;
    const numberOfSuggestions = scan?.languageImprovementComments ? scan.languageImprovementComments?.length : scan?.feedbackParagraphs ? scan?.feedbackParagraphs[0]?.languageImprovementComments?.length ?? 0 : 0;
    
    return (
        <div key={scan.id} className={optionExpanded ? style.listItemSelected : style.listItem}>
            <Link to={`/scan/${scan.contentScanId}`} className={style.listItemClickableContent}>

                <CondensedResultSlab scan={scan} paragraphs={scan.paragraphs} />
                <p className={style.defaultTextLength}>{extractFirstWords(`${scan.name}`, 80)}</p>
                <p className={style.mobileTextLength}>{extractFirstWords(`${scan.name}`, 55)}</p>
                <div className={style.right}>
                    <Flex columnGap={"1rem"} align={"center"}>

                        {/* {scan?.hasBeenDownloaded && <Tooltip label={t('scans.item.exported')} withArrow>
                            <img style={{ height: "24px", width: "auto" }} src={downloadIcon} alt=""></img>
                        </Tooltip>} */}

                        {/* {(numberOfErrors) > 0 && <Tooltip label={`${numberOfErrors} ${t('scans.item.errors')}`} withArrow>
                            <div className={style.commentNumberPreview}>
                                <img src={commentErrorIcon} alt="" className={style.commentIcon}></img>
                                <span className={style.commentNumber}>{numberOfErrors}</span>
                            </div>
                        </Tooltip>}

                        {(numberOfSuggestions) > 0 && <Tooltip label={`${numberOfSuggestions} ${t('scans.item.suggestions')}`} withArrow>
                            <div className={style.commentNumberPreview}>
                                <img src={commentOutlinedIcon} alt="" className={style.commentIcon}></img>
                                <span className={style.commentNumberPrimary}>{numberOfSuggestions}</span>
                            </div>
                        </Tooltip>} */}

                        {(numberOfErrors) > 0 && <Tooltip label={`${numberOfErrors} ${t('scans.item.errors')}`} withArrow>
                            <div className={style.errorPreview}>
                                <span className={style.errorPreviewNumber}>{numberOfErrors}</span>
                            </div>
                        </Tooltip>}

                        {(numberOfSuggestions) > 0 && <Tooltip label={`${numberOfSuggestions} ${t('scans.item.suggestions')}`} withArrow>
                            <div className={style.suggestionPreview}>
                                <span className={style.suggestionPreviewNumber}>{numberOfSuggestions}</span>
                            </div>
                        </Tooltip>}

                        <p className={`${style.defaultTextLength} ${style.date}`}>{date.toLocaleDateString()}</p>
                    </Flex>
                </div>
            </Link>

            <div className={style.optionWrapper}>
                <button className={style.optionIconButton} onClick={() => optionExpanded ? setOptionExpanded(false) : setOptionExpanded(true)}>
                    <img className={style.optionIcon} src={optionIcon} alt="options"></img>
                </button>
                {optionExpanded && <div className={style.optionMenu} ref={componentRef}>
                    <button onClick={() => { setDisplayEdit(true); setOptionExpanded(false) }} className={style.optionMenuItem}>{t('scans.item.menu.rename')}</button>
                    <button onClick={() => { deleteScan(); setOptionExpanded(false) }} className={style.optionMenuItem}>{t('scans.item.menu.delete')}</button>
                </div>}
            </div>

            <Modal isOpen={displayEdit} setIsOpen={() => setDisplayEdit()}>
                <h3>{t('scans.item.modal.title')}</h3>
                <br />
                <label>{t('scans.item.modal.rename-label')}</label><br />
                <div className={style.inputInline}>
                    <input type="text" className="input" value={name} onChange={(e) => setName(e.target.value)}></input>
                    <IconButton text={t('scans.item.modal.submit')} disabled={name.length < 2} onClick={() => updateName()} primary />
                </div>
            </Modal>

        </div>
    )
}

export default ScanListItem;

const extractFirstWords = (text, maxChars) => {
    const length = text.split("").length;
    if (length < maxChars) return text;

    let extractedText = text.substring(0, maxChars);
    const lastSpace = extractedText.lastIndexOf(" ") + 1;
    extractedText = extractedText.substring(0, lastSpace) + "...";
    return extractedText;
};
