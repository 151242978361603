import style from "./condensedResultSlab.module.css"

const CondensedResultSlab = ({ scan, paragraphs }) => {

    let numberOfAIParagraphs = 0;
    let numberOfFuzzyParagraphs = 0;

    const upperTreshold = scan.version === "2.1" ? 0.9999 : 0.9995;

    paragraphs.forEach((p) => {
        if (p.aiScore >= upperTreshold && p.aiScore <= 1) numberOfAIParagraphs++;
        else if (p.aiScore > 0.983 && p.aiScore < upperTreshold) numberOfFuzzyParagraphs++;
    })

    if( numberOfAIParagraphs > 0 ) return(
        <div className={`
        ${style.wrapper}
        ${style.colorAI}
        `}>
            <p className={style.text}>AI</p>
        </div>
    )

    if(numberOfFuzzyParagraphs / paragraphs.length > 0.5) return(
        <div className={`
        ${style.wrapper}
        ${style.colorUnsure}
        `}>
            <p className={style.text}>?</p>
        </div>
    )

    if(numberOfFuzzyParagraphs > 0) return(
        <div className={`
        ${style.wrapper}
        ${style.colorHuman}
        `}>
            <p className={style.text}>OK</p>
        </div>
    )

    return(
        <div className={`
        ${style.wrapper}
        ${style.colorHuman}
        `}>
            <p className={style.text}>OK</p>
        </div>
    )
}

export default CondensedResultSlab;