import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

// Define the languages and namespaces you want to support
const supportedLanguages = ['en', 'nb', 'se', 'dk']; // Add more languages if needed

console.log(navigator.language)

function determineLanguage() {
  const hostname = window.location.hostname;
  if (hostname === "app.ekte.ai") {
    return 'nb'; // Norwegian for ekte.ai
  } else if (hostname === "app.verklig.ai") {
    return 'se'; // Swedish for verklig.ai
  } else if (hostname === "app.virkelig.ai") {
    return 'dk'; // Danish for virkelig.ai
  } else if (hostname === "app.gradr.ai") {
    return 'en'; // English for gradr.ai
  } else {
    return 'nb'; // Default language
  }
}

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  lng: determineLanguage(),

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  detection: {
    order: ['localStorage', 'navigator'],
    lookupLocalStorage: 'i18nextLng', // Key to lookup language in localStorage
  },
});

export default i18n;