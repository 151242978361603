import { createStyles, SegmentedControl, rem, Select, MediaQuery, Text } from '@mantine/core';
import style from "./scan-examples.module.css"
import React, { useEffect, useState } from 'react';
import InfoSlab from '../../molecules/infoSlab/infoSlab';
import InfoSlabMobile from '../../molecules/infoSlabMobile/infoSlabMobile';
import { useTranslation } from 'react-i18next';

const useStyles = createStyles((theme) => ({
    root: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
        boxShadow: theme.shadows.md,
        border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[1]
            }`,
    },

    indicator: {
        backgroundImage: theme.fn.gradient({ from: 'dark', to: 'dark' }),
    },

    control: {
        border: '0 !important',
    },

    label: {
        '&, &:hover': {
            '&[data-active]': {
                color: theme.white,
            },
        },
    },
}));

const scanExampleDataNB = [
    {
        value: "human-1",
        description: "Eksempel hvor hele teksten er skrevet av et menneske",
        aiScore: 0.2968,
        text:
            `Henrik Arnold Wergeland var en norsk forfatter, samfunnsdebattant, avisredaktør og riksarkivar. Som forfatter var han svært produktiv innen en rekke sjangre, men han ble berømt for sin romantiske poesi. Wergeland er også kjent for sitt sterke engasjement for nasjonal folkeopplysning og som en forkjemper for jødenes sak i Norge.

I Norges litteraturhistorie er Wergeland kanskje den fremste av poetene. Blant høydepunktene i forfatterskapet er diktet Den første Sommerfugl fra 1837, kjærlighetsdiktet Den første Omfavnelse fra 1838 og diktene Til Foraaret og Til min Gyldenlak, som han diktet på dødsleiet. Wergeland skrev også store diktsykluser, som det grensesprengende verket Skabelsen, Mennesket og Messias fra 1830 og den formfullendte diktsyklusen Jan van Huysums Blomsterstykke fra 1840. Wergelands dikt myldrer av ideer og bilder og uttrykker en intens livsfølelse. I små detaljer speiles de store livsspørsmålene omkring kjærlighet og død, religion og historie, og identitet og menneskelighet.

Wergelands virke spenner over mange samfunnsområder. Han var fantasirik og produktiv. Hans tankesett spenner over opplysningstidens idegrunnlag, en sterk nasjonalfølelse og en romantisk livsanskuelse. At Wergeland også var beryktet for en skandalepreget livsførsel har bidratt til at han for ettertiden står som et typisk eksempel på et grenseløst, romantisk geni.`
    },
    {
        value: "human-2",
        description: "Eksempel hvor hele teksten er skrevet av et menneske. Merk at selv om teksten markeres som 'Usikker' så er det såpass stor andel tekster som får denne score-en at man ikke kan trekke en endelig konklusjon.",
        aiScore: 0.98731,
        text:
            `De to tekstene «Det lysnet i skogen» og «Byen» er skrevet av henholdsvis Jørgen Moe i 1850 og Sigbjørn Obstfelder i 1893. Både Moe og Obstfelder var norske forfattere, men kjent fra ulike litteraturhistoriske epoker. Jørgen Moe er nok mest kjent for sin innsamling og utgivelse av norske folkeeventyr sammen med Peter Christen Asbjørnsen, under romantikken. Sigbjørn Obstfelder er en av de fremste representantene for modernistisk lyrikk i Norge og blir dermed sett på som en modernist. Tekstene «Det lysnet i skogen» og «Byen» kan derfor virke svært ulike, men både tema og budskap har mange fellestrekk.

Diktet «Det lysnet i skogen» er et eksempel på et typisk romantisk verk. Motivet i diktet kan sies å være at det lyriske jeget ser ut mot bygda og tenker tilbake på barndommen sin der. Det kan tenkes at livet på landet var bedre enn livet på byen, noe som også kan tenkes å være budskapet. Det lyriske jeget blir grepet av «en lengsel til skog og til fjell» og kjenner på at skogen og fjellvannet hadde «for første gang suset sin vemodstanke inn». Her kan det tenkes at det lyriske jeget blir vemodig av å tenke tilbake på den svunne tiden i sitt barndomshjem, i kontrast med livet han/hun har nå.

Et tema som kommer tydelig fram er lengsel. Det lyriske jeget lengter tilbake til naturen når han/hun «går i den myldrende by». Lengselen til det lyriske jeget er intens, og man får et inntrykk av at barndomshjemmet og naturen stod han/hun nær. Et annet tema kan være fremmedgjorthet.`
    },
    {
        value: "human-3",
        description: "Eksempel på en vurdering av en tekst skrevet av et menneske som inneholder både tekst og kjemiske formler.",
        aiScore: 0,
        text:
            `Fotosyntese er en prosess hvor planter, alger og noen typer bakterier fanger lysenergi og lagrer det som kjemisk energi. Vann og karbondioksid tas opp og omdannes til karbohydrater som brukes og lagres, og oksygengass, hvor noe brukes og noe slippes ut i lufta. Nesten alt liv er avhengig av det som lages i fotosyntesen.
Fotosyntesen er den viktigste livsprosessen på jorda fordi det dannes energirike molekyler som er viktig næring for alle som ikke selv bedriver fotosyntese. Disse molekylene brukes både til oppbygging av celler, og til prosesser inne i cellene. Samtidig tas det opp CO2 fra atmosfæren som gjøres om til organisk karbon i organismene. Oksygenet som frigis i fotosyntesen er nødvendig for celleånding.

Fotosyntesen bedriver naturlig karbonfangst når det tas opp CO2, og å ta vare på levende natur er et klimatiltak.

Formel for fotosyntese
Zoom inn
Reaksjonslikning for fotosyntese. Fargede piler er tegnet inn for å vise at oksygengass i atmosfæren (O2) kommer fra vannmolekyler (H2O) som spaltes, og ikke fra karbondioksid (CO2).

Fotosyntese kan oppsummeres med formelen

6CO2+12H2O⟶C6H12O6+6O2+6H2O
Denne formelen for fotosyntese er en forenkling. Fotosyntese består av tre viktige prosesser:

produksjon av kjemisk energi i form av ATP (adenosintrifosfat).
karbonfiksering, der CO2 fra luft brukes til å bygge store organiske molekyler som for eksempel sukker, C6H12O6.
spalting av vann, H2O, som frigir oksygengass, O2.`
    },
    {
        value: "gpt-3.5",
        description: "Eksempel på en vurdering av en tekst som er fullstendig skrevet av ChatGPT (GPT-3.5, 26.08.2023).",
        aiScore: 0.99979,
        text:
            `Henrik Wergeland var en nasjonalistisk dikter og forfatter som kjempet for norsk språk og kultur. Han ønsket å fremme bruk av norsk i skrift og tale, og han arbeidet for å revitalisere og modernisere det norske språket. Wergeland argumenterte for at norsk var et eget språk og at det var viktig for nasjonal selvfølelse å ha et eget litterært språk som kunne uttrykke norske tanker og følelser mer autentisk enn dansk.

På den andre siden hadde vi Peter Andreas Munch, en historiker og filolog som også var involvert i språkdebatten. Munch var mer konservativ og mente at det norske språket var for ungt og underutviklet til å kunne fungere som et fullverdig skriftspråk. Han fryktet at en overgang til norsk skriftspråk kunne føre til språklig kaos og tap av forståelse mellom de nordiske landene.

Konflikten mellom Wergeland og Munch representerte to motstridende syn på nasjonal identitet og språklig uttrykk. Wergeland ønsket å skape en norsk kulturell identitet gjennom språket, mens Munch argumenterte for å opprettholde dansk som det offisielle skriftspråket for å bevare en viss grad av kulturell og språklig kontinuitet.

På lang sikt vant Wergelands synspunkter gradvis frem. Norsk språk og nasjonal identitet ble styrket gjennom nasjonsbyggingen i løpet av 1800-tallet, og i 1885 ble det vedtatt at norsk skulle være det offisielle skriftspråket i Norge. Denne perioden markerer en viktig milepæl i utviklingen av det moderne norske språket og nasjonen.`
    },
    {
        value: "gpt-4",
        description: "Eksempel på en vurdering av en tekst som er fullstendig skrevet av GPT-4 i ChatGPT (GPT-4, 26.08.2023).",
        aiScore: 0.99969,
        text:
            `I de første tiårene av 1800-tallet, idet Norge fortsatt formet sin nasjonale identitet etter Napoleonskrigene og Kielfreden, oppsto en intellektuell tvekamp som skulle definere nasjonens kulturelle retning. Sentralt i denne debatten sto to giganter: Henrik Wergeland og Peter Andreas Munch. Deres uenighet dreide seg om noe så grunnleggende som det norske språket – hvordan skulle det se ut, og hva skulle det representere?

Som poet, forfatter og nasjonalromantiker var Wergeland drevet av en lidenskap for folket, dets sjel og dets kultur. I språkdebatten, kjent som 'sprogstriden', argumenterte Wergeland for en reform av det dansk-norske skriftspråket. Han ønsket å bringe skriftspråket nærmere talespråket i Norge, særlig bymålet i Oslo, for å reflektere den norske virkeligheten bedre. Hans tilnærming var ikke radikal, men gradvis. Han foreslo en trinnvis fornorskning, hvor man over tid ville inkludere elementer fra norske dialekter og avvike fra det danske skriftspråket som hadde vært dominerende siden unionstiden.

Munch, på den annen side, hadde et annet fokus. Som historiker var han dypt opptatt av Norges eldste historie, og han så verdien i å bevare båndene til dansk kultur og skriftspråk. Munch argumenterte for at den felles skriftkulturen med Danmark var et viktig redskap for å sikre Norges kulturelle og litterære arv. Å bryte med denne arven kunne risikere å svekke landets posisjon i den skandinaviske kulturelle sfæren.`
    },
    {
        value: "mixed",
        description: "Eksempel på en vurdering av en tekst hvor de to første avsnittene er skrevet av et menneske og hvor de to resterende er skrevet av ChatGPT (GPT-3.5, 26.08.2023).",
        aiScore: 0.99839,
        text:
            `Henrik Arnold Wergeland var en norsk forfatter, samfunnsdebattant, avisredaktør og riksarkivar. Som forfatter var han svært produktiv innen en rekke sjangre, men han ble berømt for sin romantiske poesi. Wergeland er også kjent for sitt sterke engasjement for nasjonal folkeopplysning og som en forkjemper for jødenes sak i Norge.

I Norges litteraturhistorie er Wergeland kanskje den fremste av poetene. Blant høydepunktene i forfatterskapet er diktet Den første Sommerfugl fra 1837, kjærlighetsdiktet Den første Omfavnelse fra 1838 og diktene Til Foraaret og Til min Gyldenlak, som han diktet på dødsleiet.

Wergeland skrev også store diktsykluser, som det grensesprengende verket Skabelsen, Mennesket og Messias fra 1830, der han utforsket både religiøse og filosofiske temaer. Hans diktsyklus Jan van Huysums Blomsterstykke fra 1840 viser hans kunstneriske allsidighet og evne til å skape vakre, visuelle bilder gjennom ord. Wergelands dikt myldrer av ideer og bilder og uttrykker en intens livsfølelse. I små detaljer speiles de store livsspørsmålene omkring kjærlighet og død, religion og historie, og identitet og menneskelighet. Gjennom sitt mangfoldige forfatterskap har Wergeland etterlatt et varig avtrykk i norsk kulturhistorie, og hans innflytelse kan fortsatt merkes i dagens litteratur og samfunnsdebatt.

Wergelands betydning som samfunnsdebattant og aktivist er like bemerkelsesverdig som hans litterære prestasjoner. Hans engasjement for ytringsfrihet, toleranse og likeverd gjorde ham til en stemme for de undertrykte og marginaliserte. Hans innsats for jødenes rettigheter i Norge, som kulminerte i fjerningen av diskriminerende lover i 1851, står som et eksempel på hans mot og standhaftighet i kampen for menneskerettigheter.`
    }
];

const scanExampleDataEn = [
    {
        value: "human-1",
        description: "Example where the entire text is written by a human",
        aiScore: 0.2968,
        text:
            `Henrik Arnold Wergeland was a Norwegian author, social commentator, newspaper editor, and National Archivist. As an author, he was highly productive across a range of genres, but he became famous for his romantic poetry. Wergeland is also known for his strong commitment to national education and as an advocate for the Jewish cause in Norway.
    
In the history of Norwegian literature, Wergeland is perhaps the foremost of the poets. Among the highlights of his works are the poem The First Butterfly from 1837, the love poem The First Embrace from 1838, and the poems To Spring and To My Wallflower, which he wrote on his deathbed. Wergeland also wrote large poetic cycles, like the groundbreaking work The Creation, Man and Messiah from 1830 and the perfectly formed poetic cycle Jan van Huysums Blomsterstykke from 1840. Wergeland's poems are teeming with ideas and images and express an intense feeling of life. In small details, the great life questions about love and death, religion and history, identity, and humanity are reflected.
    
Wergeland's work spans many areas of society. He was imaginative and productive. His mindset covers the ideological foundation of the Enlightenment, a strong national sentiment, and a romantic view of life. That Wergeland was also notorious for a scandalous way of living has contributed to him being seen by posterity as a typical example of a boundless, romantic genius.`
    },
    {
        value: "human-2",
        description: "Example where the entire text is written by a human. Note that even though the text is marked as 'Uncertain', there is a significant enough proportion of texts that receive this score that one cannot draw a final conclusion.",
        aiScore: 0.98731,
        text:
            `The two texts "It lightened in the forest" and "The City" are written by Jørgen Moe in 1850 and Sigbjørn Obstfelder in 1893, respectively. Both Moe and Obstfelder were Norwegian authors, but known from different literary historical epochs. Jørgen Moe is probably best known for his collection and publication of Norwegian folk tales together with Peter Christen Asbjørnsen, during the Romantic era. Sigbjørn Obstfelder is one of the foremost representatives of modernist poetry in Norway and is thus seen as a modernist. Therefore, the texts "It lightened in the forest" and "The City" may seem very different, but both theme and message have many similarities.
    
The poem "It lightened in the forest" is an example of a typical romantic work. The motif in the poem can be said to be that the lyrical I looks out towards the village and thinks back to his/her childhood there. It might be thought that life in the countryside was better than life in the city, which could also be considered the message. The lyrical I is seized by "a longing for the forest and the mountains" and feels that the forest and the mountain lake had "for the first time whispered their melancholy thought in". Here, it may be thought that the lyrical I becomes melancholic when thinking back to the bygone days in his/her childhood home, in contrast to the life he/she has now.
    
A theme that comes clearly forward is longing. The lyrical I longs back to nature when he/she "walks in the bustling city". The longing of the lyrical I is intense, and one gets an impression that the childhood home and nature were close to him/her. Another theme could be alienation.`
    },
    {
        value: "human-3",
        description: "Example of an assessment of a text written by a human that contains both text and chemical formulas.",
        aiScore: 0,
        text:
            `Photosynthesis is a process where plants, algae, and some types of bacteria capture light energy and store it as chemical energy. Water and carbon dioxide are absorbed and converted into carbohydrates, which are used and stored, and oxygen gas, some of which is used and some released into the air. Almost all life depends on what is produced in photosynthesis.
Photosynthesis is the most important life process on Earth because it forms energy-rich molecules that are important nutrients for all who do not themselves perform photosynthesis. These molecules are used both for the construction of cells and for processes inside the cells. At the same time, CO2 is absorbed from the atmosphere and converted into organic carbon in organisms. The oxygen released in photosynthesis is necessary for cellular respiration.

Photosynthesis performs natural carbon capture when it absorbs CO2, and preserving living nature is a climate measure.

Formula for photosynthesis
Zoom in
Reaction equation for photosynthesis. Colored arrows are drawn in to show that the oxygen gas in the atmosphere (O2) comes from water molecules (H2O) that are split, and not from carbon dioxide (CO2).

Photosynthesis can be summarized with the formula

6CO2 + 12H2O ⟶ C6H12O6 + 6O2 + 6H2O
This formula for photosynthesis is a simplification. Photosynthesis consists of three important processes:

production of chemical energy in the form of ATP (adenosine triphosphate).
carbon fixation, where CO2 from the air is used to build large organic molecules such as sugar, C6H12O6.
splitting of water, H2O, which releases oxygen gas, O2.`
    },
    {
        value: "gpt-3.5",
        description: "Example of an assessment of a text that is completely written by ChatGPT (GPT-3.5, 26.08.2023).",
        aiScore: 0.99979,
        text:
            `Henrik Wergeland was a nationalistic poet and author who fought for the Norwegian language and culture. He wanted to promote the use of Norwegian in writing and speech and worked to revitalize and modernize the Norwegian language. Wergeland argued that Norwegian was a separate language and that having a literary language of its own was important for national self-esteem, as it could more authentically express Norwegian thoughts and feelings than Danish.
    
On the other hand, we had Peter Andreas Munch, a historian and philologist who was also involved in the language debate. Munch was more conservative and believed that the Norwegian language was too young and underdeveloped to function as a fully-fledged written language. He feared that a transition to Norwegian as a written language could lead to linguistic chaos and loss of understanding between the Nordic countries.

The conflict between Wergeland and Munch represented two opposing views on national identity and linguistic expression. Wergeland wanted to create a Norwegian cultural identity through language, while Munch argued for maintaining Danish as the official written language to preserve a degree of cultural and linguistic continuity.

In the long term, Wergeland's views gradually prevailed. The Norwegian language and national identity were strengthened through nation-building in the 19th century, and in 1885 it was decided that Norwegian should be the official written language in Norway. This period marks an important milestone in the development of the modern Norwegian language and nation.`
    },
    {
        value: "gpt-4",
        description: "Example of an assessment of a text that is completely written by GPT-4 in ChatGPT (GPT-4, 26.08.2023).",
        aiScore: 0.99969,
        text:
            `In the first decades of the 19th century, as Norway was still shaping its national identity after the Napoleonic Wars and the Treaty of Kiel, an intellectual duel emerged that would define the nation's cultural direction. Central in this debate were two giants: Henrik Wergeland and Peter Andreas Munch. Their disagreement revolved around something as fundamental as the Norwegian language – what should it look like, and what should it represent?
    
As a poet, author, and national romantic, Wergeland was driven by a passion for the people, their soul, and their culture. In the language debate, known as 'sprogstriden', Wergeland argued for a reform of the Danish-Norwegian written language. He wanted to bring the written language closer to the spoken language in Norway, especially the urban dialect of Oslo, to better reflect the Norwegian reality. His approach was not radical but gradual. He proposed a step-by-step Norwegianization, where elements from Norwegian dialects would be incorporated over time, diverging from the Danish written language that had been dominant since the union era.

Munch, on the other hand, had a different focus. As a historian, he was deeply concerned with Norway's ancient history, and he saw value in preserving ties to Danish culture and written language. Munch argued that the shared written culture with Denmark was an important tool to ensure Norway's cultural and literary heritage. Breaking with this legacy could risk weakening the country's position in the Scandinavian cultural sphere.`
    },
    {
        value: "mixed",
        description: "Example of an assessment of a text where the first two paragraphs are written by a human and the remaining two are written by ChatGPT (GPT-3.5, 26.08.2023).",
        aiScore: 0.99839,
        text:
            `Henrik Arnold Wergeland was a Norwegian author, social debater, newspaper editor, and National Archivist. As an author, he was very productive in a range of genres, but he became famous for his romantic poetry. Wergeland is also known for his strong commitment to national public education and as a champion for the cause of Jews in Norway.
    
In the history of Norwegian literature, Wergeland is perhaps the foremost of the poets. Among the highlights of his works are the poem The First Butterfly from 1837, the love poem The First Embrace from 1838, and the poems To Spring and To My Wallflower, which he composed on his deathbed.

Wergeland also wrote large poetic cycles, such as the groundbreaking work The Creation, Man, and Messiah from 1830, where he explored both religious and philosophical themes. His poetic cycle Jan van Huysums Blomsterstykke from 1840 demonstrates his artistic versatility and ability to create beautiful, visual images through words. Wergeland's poems are teeming with ideas and images and express an intense feeling of life. In small details, the great life questions about love and death, religion and history, and identity and humanity are reflected. Through his diverse body of work, Wergeland has left a lasting imprint on Norwegian cultural history, and his influence can still be felt in today's literature and social debate.

Wergeland's significance as a social debater and activist is as remarkable as his literary achievements. His commitment to freedom of speech, tolerance, and equality made him a voice for the oppressed and marginalized. His efforts for the rights of Jews in Norway, culminating in the removal of discriminatory laws in 1851, stand as an example of his courage and steadfastness in the fight for human rights.`
    }
];

const scanExampleDataSE = [
    {
        value: "human-1",
        description: "Exempel där hela texten är skriven av en människa",
        aiScore: 0.0108166,
        text:
            `En båt som glider fram på en kanal mitt inne i landet ter sig lätt som något overkligt. Det är 'oväntat' att möta en sådan när man färdas på vägarna. Dikten innehåller kontraster såväl mellan biltrafikens rörelse och båtens stilla glidande som mellan det rökiga inlandet och båtens vithet. Båten blir en vit luffare. Luffaren är i rörelse, men hans rörelse är fri, oprogrammerad. Luffaren står utan för samhälle och arbete, och han har, i Sverige inte minst genom Harry Martinsons diktning, blivit en representant för det själsliga. 

Vi ser alltså i dikt efter dikt hur nutidsmän niskorna skildras som jagade, fångna i vardagsruti nerna. Vi ser dem som anonyma, kontaktlösa. Men de äger en väntan. Få människor får det de väntar på, deras väntan är tyst och trängd. 
        
Det som finns utanför vardagen framställs på olika sätt hos Tranströmer. I den tidigare diktning en är det oftast något gåtfullt, en uppenbarelse, en 'epifani', som Espmark har kallat det i sin under sökning av temat. Detta hemlighetsfulla har Tran strömer vanligen inget namn på, det är 'hemlighe terna på vägen'. Men vi har också sett att världen utanför vardagen inte sällan tar gestalt av landskap, fri rymd och grönska. Det hemlighetsfulla kan ock så framträda som ett medvetande om historien, som ett stort tidsperspektiv. Till sist är det som närhet, som verklig kontakt ansikte mot ansikte, utan masker, som det framträder. Alla dessa teman har skickligt om än med skiftande uppmärksamhet be handlats av Kjell Espmark i hans monografi. När jag nu åter tar upp dem är det för att närmare beskriva det som sker vid gränsen mellan de båda 'världarna', för att bestämma hur Tranströmer ser på möjligheten att med språkets hjälp etablera kon takt med eller beskriva kontakten med det gåtfulla.`
    },
    {
        value: "human-2",
        description: "Exempel där hela texten är skriven av en människa. Observera att även om texten markeras som 'Osäker' så är det en så pass stor andel texter som får denna bedömning att man inte kan dra en slutgiltig slutsats.",
        aiScore: 0.98351,
        text:
            `Det räcker inte med att se naturen, man måste komma närmare, längre in, röra vid den. Naturens kraft ligger i att den är mänsklig, att träden och stenarna vandrar, att de har kontakt med varandra: 

De lämnade kvar en väldigare tystnad på jorden och ett träds lugna steg, ett träds lugna steg. 
'Klangen' i Den halvfärdiga himlen 

Jag kommer för sällan fram till vattnet. Men nu är jag här, bland stora stenar med fridfulla ryggar. Stenar som långsamt vandrat baklänges upp ur vågorna. 
'Långsam musik' i Klanger och spår 

Utanför byn går vägen länge mellan skogens träd. Och träden träden tigande i endräkt med varann. 'Noctume' i Den halvfärdiga himlen 

Omedelbar upprepning av ord eller ordgrupp, epi- zeuxis, hör inte till det vanliga i Tranströmers stil. I två av citaten har han emellertid denna figur, och i bägge fallen rör det sig om träd. 

Prosadikten 'Böljan på senhöstnattens roman' handlar om hur diktjaget går ut på ön som 'en Qäril som just krupit ur puppskalet', varvid 'plastpå sarna i vardera handen hänger som missbildade vingar'.`
    },
    {
        value: "human-3",
        description: "Exempel på en bedömning av en text skriven av en människa som innehåller både text och kemiska formler.",
        aiScore: 0.031622,
        text:
            `Fotosyntes är den process där växter och andra levande organismer tar hand om energi från solljus och lagrar energin i kemiska bindningar.

Den vanligaste formen är kolsyreassimilation hos växter och cyanobakterier, som innebär att de under dagen tar in koldioxid, vatten och solenergi som de med hjälp av klorofyll omvandlar till syre och druvsocker. Syret och druvsockret använder de vid cellandningen och under natten när de avger koldioxid.

Fotosyntesen i växter försiggår i bladen vars celler har kloroplaster, som anses vara symbiotiska cyanobakterier. Ljusreaktionerna sker i anslutning till tylakoidernas fosfolipidmembraner, medan mörkerreaktionen äger rum i det vätskefyllda stromat.

Fotosyntes utan syreproduktion anses vara en mer primitiv process och förekommer fortfarande hos bakterier.

Kemisk formel för koldioxidfixerande fotosyntes:

6 H2O + 6 CO2 + ljusenergi → C6H12O6 (druvsocker) + 6 O2

Vissa bakterier, cyanobakterier, utnyttjar vatten vid fotosyntes, på samma sätt som växter. Andra fotoautotrofa bakterier klarar inte av detta, utan använder istället H2S (svavelväte), som är en mer lättoxiderad förening.

Beskrivningen av den kemiska reaktionen är starkt förenklad jämfört med den faktiska processen som äger rum i kloroplasterna. Koldioxiden binds i själva verket genom en lång process med en rad katalysatorer och kemiska reaktioner i flera olika steg i mellanleden. Denna reaktionssekvens kallas Calvin-cykeln.

Antennpigment är de pigmentmolekyler som fångar in solljuset och transporterar vidare energin till klorofyll i närliggande reaktionscentra där fotosyntesen sker. De deltar inte i själva syntesen. Exempel på molekyler som kan fungera som antennpigment hos växter är klorofyll a, klorofyll b och karotener. Rödalger och cyanobakterier har fykobiliproteiner, och brunalger en karotenoid vid namn fukoxantol.`
    },
    {
        value: "gpt-3.5",
        description: "Exempel på en bedömning av en text som är helt skriven av ChatGPT (GPT-3.5, 21.12.2023)",
        aiScore: 0.99963,
        text:
            `Tomas Tranströmer är känd för sin förmåga att skapa poetiska landskap som drar in läsaren i en värld av djup reflektion och känslomässig laddning. I hans dikt 'Östersjöar' utforskar han en rad teman som är typiska för hans verk, inklusive naturens skönhet, den mänskliga existensens gåtfullhet och tidens gång.
    
Dikten 'Östersjöar' är en kort och koncis skildring av en specifik upplevelse. Tranströmer tar oss med på en resa till den svenska skärgården, där vi kan föreställa oss de glittrande östersjöarna, troligen under sommarsäsongen. Redan i det första raderna använder han starka visuella bilder och ett skarpt sinne för detaljer för att skapa en levande bild av landskapet:

'Östersjöarna glittrar
i morgonkvällen.'

Med dessa inledande rader fångar han läsarens uppmärksamhet och skapar en atmosfär av skönhet och lugn. Genom att använda ordet 'glittrar' målar han upp en bild av vattenytan som reflekterar kvällssolen, och detta skapar en stark känsla av harmoni och ro.

Dikten fortsätter sedan med att utforska temat för tidens gång och livets förändring. Tranströmer skriver:

'Kvällen
gör sig redo
och plåtar över oss
en tystnad.'

Här använder han ord som 'Kvällen' och 'tystnad' för att förmedla en känsla av avslut och förändring. Genom att säga att kvällen 'plåtar över oss' ger han intrycket av att ögonblicket är en sorts fotografisk ögonblicksbild, som kommer att bevaras i minnet.`
    },
    {
        value: "gpt-4",
        description: "Exempel på en bedömning av en text som är helt skriven av GPT-4 i ChatGPT (GPT-4, 21.12.2023)",
        aiScore: 0.99981,
        text:
            `Dikten 'Skiss i Oktober' skildrar en naturbild och reflekterar över människans plats i universum. Tranströmers språk är bildrikt och använder ofta metaforer och symboler för att förmedla sina tankar och känslor. Till exempel, diktens inledande rader 'Trädens ljusgröna damm suger sig fast vid mina kinder' använder en metafor som jämför träden med en levande varelse som kan röra och påverka läsaren direkt. Detta skapar en känsla av närhet och förbundenhet med naturen.
        
Tranströmer leker också med perspektiv och tid i dikten. Han växlar mellan det storslagna - universum och årstidernas växlingar - och det lilla, individuella perspektivet. Denna teknik speglar dikternas teman om mänsklig existens och vår plats i världen.
        
Språket i dikten är kompakt och varje ord är noga utvalt, vilket är typiskt för Tranströmers stil. Hans diktning kräver ofta en aktiv läsare som är villig att reflektera över både det uttryckta och det outtalade. Det finns en rytm och en musikalitet i språket, vilket inte är förvånande med tanke på Tranströmers bakgrund som pianist.
        
'Skiss i Oktober' är en dikt som uppmanar läsaren att reflektera över livets förgänglighet och skönheten i det flyktiga ögonblicket. Det är ett utmärkt exempel på Tranströmers förmåga att väva samman det konkreta med det abstrakta, det personliga med det universella.`
    },
    {
        value: "mixed",
        description: "Exempel på en bedömning av en text där de två första styckena är skrivna av en människa och de två återstående är skrivna av ChatGPT (GPT-4, 21.12.2023).",
        aiScore: 0.99185,
        text:
            `En båt som glider fram på en kanal mitt inne i landet ter sig lätt som något overkligt. Det är 'oväntat' att möta en sådan när man färdas på vägarna. Dikten innehåller kontraster såväl mellan biltrafikens rörelse och båtens stilla glidande som mellan det rökiga inlandet och båtens vithet. Båten blir en vit luffare. Luffaren är i rörelse, men hans rörelse är fri, oprogrammerad. Luffaren står utan för samhälle och arbete, och han har, i Sverige inte minst genom Harry Martinsons diktning, blivit en representant för det själsliga. 
        
Vi ser alltså i dikt efter dikt hur nutidsmän niskorna skildras som jagade, fångna i vardagsruti nerna. Vi ser dem som anonyma, kontaktlösa. Men de äger en väntan. Få människor får det de väntar på, deras väntan är tyst och trängd.
        
I denna diktvärld framträder båten som en metafor för frihet och avskildhet från det stressiga vardagslivet. Det är en symbol för det ouppnåeliga, en stilla fläkt av hopp i den annars gråa tillvaron. Diktens språk speglar denna kontrast genom att använda mjuka, svepande beskrivningar av båtens rörelse, som en lugn oas i en stormig värld. Dikten blir därmed en kommentar till vår tids eviga strävan och längtan efter lugn och ro, en undflykt från det eviga bruset i våra liv.
        
Samtidigt skildrar diktaren också en djupare existentiell längtan. Båten är inte bara en fysisk resa bort från vardagens stress, utan också en symbolisk resa mot inre frid och självinsikt. Genom att placera båten i en oväntad miljö, skapar diktaren en känsla av avkoppling och eftertanke. Båten och luffaren blir metaforer för en längtan efter en mer äkta och mindre mekanisk existens, en strävan efter att bryta sig loss från samhällets normer och krav för att finna en egen, mer självständig väg i livet.`
    }
];

const scanExampleDataDk = [
    {
        value: "human-1",
        description: "Eksempel hvor hele teksten er skrevet af et menneske.",
        aiScore: 0.01005182,
        text:
            `Den rytme, du oplever i moderne lyrik, kan hente inspiration fra en ryt­me i vore omgivelser eller den rytme, du kender fra kroppen. Knyttes rytmen til vores omgivelser, kan det fx være naturen eller storbyen.
Knyttes rytmen til vores krop, kan det være åndedrættet eller pulsen, der danner rytmen.

Hvilken rytme forbinder du med storbyen?
Hvilken rytme forbindes med naturen?
Og hvilken rytme finder du i de uddrag, der er bragt ovenfor fra Dan Turèlls digt Minder den mest om det du opfatter som storby-rytme eller natur-rytme?

Som du sikkert finder frem til ved at skulle svare på disse spørgsmål an­gående rytme, kan man ikke give et entydigt svar. Svaret afhænger i høj grad af den sammenhæng, som rytmen indgår i. Handler digtet fx om stormvejr og orkan, vil rytmen sikkert være mere markeret, end hvis digtet behandler en stille sommermorgen.

I den ældre lyrik er rytmen knyttet til den opbygning, der anvendes. Der bruges enderim i forskellige systemer, og digteren arbejder som før omtalt med et fastlagt system af rytmestrukturer, hvor der bruges tryk­svage (ubetonede) og trykstærke (betonede) markeringer (fx jamber (u-), trokæer (-o)) i systemer, som digteren vælger.

Når man skrev digte før i tiden, var den fastlagte rytme en del af det regelsæt, man var under­kastet. På samme måde som man som borger i samfundet var underkastet en lang række meget faste normer for moral og dannelse. `
    },
    {
        value: "human-2",
        description: "Eksempel hvor hele teksten er skrevet af et menneske.",
        aiScore: 0.95226,
        text:
            `Udover lovforslag fremsættes der hvert år en lang række beslutningsforslag. Lovforslag og beslutningsforslag kan fremsættes af både folketingsmedlemmer og ministre, men hvor ministre typisk fremsætter lovforslag, fremsætter folketingsmedlemmer oftest beslutningsforslag. 

Beslutningsforslag er simplere og hurtigere at udforme end lovforslag, fordi de ikke skal være så detaljerede.

Beslutningsforslag hedder formelt »forslag til folketingsbeslutning«, og de fremsættes og forhandles stort set ligesom lovforslag i Folketingssalen. De fleste beslutningsforslag fremsættes af regeringens modstandere – oppositionen – og er formuleret, så de pålægger regeringen en handling. Det kan f.eks. være at pålægge regeringen at fremsætte et lovforslag med et bestemt indhold eller opfordre regeringen til at undersøge en sag nærmere.

Beslutningsforslag kan måske blive til lov en dag
Fordi de fleste beslutningsforslag bliver fremsat af oppositionen, bliver de oftest stemt ned eller når kun til at blive behandlet i udvalget og bortfalder senere, når folketingsåret slutter. At plante nye politiske ideer eller få regeringen til at tage initiativ til ny lovgivning er noget af det, oppositionspartierne gerne vil opnå, når de fremsætter beslutningsforslag. Beslutningsforslagene skal diskuteres i Folketingssalen af partiernes ordførere og ministeren på fagområdet. Ordførerne og ministeren tvinges på den måde til at forholde sig til de emner, forslagene omhandler.

Beslutningsforslag kan sætte den politiske dagsorden
Nogle gange fremsætter et oppositionsparti næsten ens forslag flere år i træk. Det presser dets politiske modstandere til at tage stilling til en sag, der måske ellers ikke ville komme på den politiske dagsorden. Hvis regeringspartier synes, at en idé fra et beslutningsforslag er rigtig god, sker det nogle gange, at ideen efterfølgende optræder helt eller delvis i et af regeringens lovforslag. Oppositionspartiet, der har fået ideen i sin tid, får ikke æren – men det får noget af sin politik gennemført. `
    },
    {
        value: "human-3",
        description: "Eksempel på en bedømmelse af en tekst skrevet af et menneske, som indeholder både tekst og kemiske formler.",
        aiScore: 0.91,
        text:
            `Fotosyntese er en af de vigtigste biologiske processer på Jorden, da den producerer det meste af atmosfærens oxygen (ilt), som næsten alt liv afhænger af. Fotosyntesen foregår især i grønne planter, alger og visse bakterier.Formlen for fotosyntese

I fotosyntesen omdannes kuldioxid og vand ved hjælp af sollysets energi til oxygen og organisk stof i form af glukose (druesukker). Glukose omdannes siden til andre næringsstoffer, der bruges af planterne selv og af dyr.

Formlen for fotosyntesen skrives ofte sådan:

kuldioxid + vand + sollys → glukose + oxygen

eller mere præcist:

6 CO2 + 6 H2O + sollys → C6H12O6 + 6 O2

Fotosyntese er af altafgørende betydning for opretholdelsen af Jordens stofkredsløb. Ved fotosyntese dannes årligt ca. 200 milliarder tons organiske forbindelser. Det svarer til, at al atmosfærens kuldioxid i løbet af en periode på 300 år omdannes til organisk stof, og at al atmosfærens oxygen fornyes i løbet af 2000 år. Når atmosfærens indhold af kuldioxid og oxygen (hhv. 0,042 % og 21 %) alligevel holder sig næsten konstant, skyldes det, at alle levende organismer, der ikke kan udføre fotosyntese, kræver energi tilført i form af næringsstoffer for at kunne opretholde livsfunktionerne. Næringsstofferne er typisk de organiske forbindelser, der dannes ved fotosyntese, og energien frigives ved nedbrydning til kuldioxid og vand under forbrug af oxygen ved ånding, respiration (se respiration hos planter). Også planter og alger nedbryder konstant organiske forbindelser ved respiration, men i lys overstiger fotosyntese respirationen, således at der finder en nettostofproduktion sted.`
    },
    {
        value: "gpt-3.5",
        description: "Eksempel på en bedømmelse af en tekst, som er helt skrevet af ChatGPT (GPT-4o, 21.10.2024).",
        aiScore: 0.9995,
        text:
            `Ibsen bruger replikker og dialog til at afsløre de psykologiske spændinger mellem karaktererne. For eksempel er Torvalds sprog ofte belærende og patroniserende over for Nora, mens Noras sprog i begyndelsen af stykket er barnligt og uskyldigt. Men som hendes erkendelse af virkeligheden vokser, ændres hendes sprog også – det bliver mere alvorligt og reflekterende.

En vigtig sproglig teknik er brugen af ironi. Nora skjuler sine sande følelser og hensigter bag et smil og lette bemærkninger, især i forhold til Helmer. Dette skjulte lag af ironi understreger konflikten mellem hendes ydre facade og indre kamp. En anden vigtig teknik er gradvis afsløring. Stykkets dramatiske struktur bygger på, at Noras hemmelighed – at hun har forfalsket sin fars underskrift for at redde Helmers liv – langsomt afsløres og intensiverer spændingen frem mod den endelige opgørelse mellem ægtefællerne.

Et dukkehjem er en skelsættende tekst, der udfordrer samtidens syn på køn og ægteskab. Gennem symbolik, dialog og psykologisk dybde fremstiller Ibsen Noras rejse fra undertrykkelse til selvbevidsthed som en universel kamp for frihed og personlig integritet. Stykket er en mesterlig kritik af de begrænsninger, som det patriarkalske samfund pålægger kvinder, og har fortsat relevans i nutidens diskussioner om kønsroller og ligestilling.`
    },
    {
        value: "gpt-4",
        description: "Eksempel på en bedømmelse af en tekst, som er helt skrevet af o1-preview modellen fra OpenAI i ChatGPT (o1-preview, 21.10.2024).",
        aiScore: 0.9997,
        text:
            `Et dukkehjem', skrevet af den norske dramatiker Henrik Ibsen i 1879, er et banebrydende drama, der kritisk undersøger det borgerlige ægteskab og kvindens rolle i det 19. århundredes samfund. Gennem hovedpersonen Nora Helmer udforsker Ibsen temaer som individualitet, frihed og selvrealisering, mens han stiller spørgsmålstegn ved de sociale konventioner, der begrænser menneskets udfoldelse.

Stykket følger Nora, en tilsyneladende lykkeligt gift kvinde med tre børn. Hendes mand, Torvald Helmer, er netop blevet forfremmet til bankdirektør, hvilket lover økonomisk stabilitet og social status for familien. Under overfladen bærer Nora dog på en tung hemmelighed: Hun har ulovligt lånt penge for at finansiere en livsvigtig rejse til Italien for Torvald, der var alvorligt syg. For at få lånet forfalskede hun sin afdøde fars underskrift. Da lånegiveren, Nils Krogstad, truer med at afsløre hendes handling, begynder Noras verden at krakelere, og hun konfronteres med realiteterne i sit ægteskab og samfundets forventninger.

Ibsen udforsker kvindens selvstændighed og frigørelse som et centralt tema. Nora gennemgår en betydelig udvikling fra at være en naiv og underdanig hustru til at blive en selvbevidst kvinde, der søger sin egen identitet uden for ægteskabets og samfundets begrænsninger. Hendes beslutning om at forlade sin mand og børn i slutningen af stykket var yderst kontroversiel og udfordrede de gængse normer for kvinders rolle i familien.

Et andet fremtrædende tema er kontrasten mellem illusion og virkelighed. Ægteskabet mellem Nora og Torvald er bygget på illusioner; Torvald ser Nora som en dukke eller et barn snarere end som en ligeværdig partner. Han værdsætter hende for hendes skønhed og charme, men tager hende ikke alvorligt som individ. Gennem krisen med Krogstad afsløres de dybe sprækker i deres forhold, og facaden af det perfekte ægteskab falder sammen.`
    },
    {
        value: "mixed",
        description: "Eksempel på en bedømmelse af en tekst, hvor de første afsnit er skrevet af et menneske, og de resterende er skrevet af ChatGPT (GPT-4o, 21.10.2024).",
        aiScore: 0.9928,
        text:
            `Magtfordeling, der kan organiseres meget forskelligt, er et centralt kendetegn ved en demokratisk stat; den konkrete udformning er afhængig af, om magtfordelingen skal virke i et parlamentarisk demokrati som det danske, britiske og tyske eller i et helt eller delvist præsidentialstyre som det amerikanske og franske. Sikringen mod magtmisbrug kan enten være en følge af, at flere organer skal samvirke i indbyrdes afhængighed, dvs. magtspredning som fx i Tyskland, eller af, at de enkelte funktioner reserveres uafhængigt virkende organer, dvs. magtadskillelse som fx i USA.

I et parlamentarisk demokrati som det danske er der en tæt forbindelse mellem den lovgivende og den udøvende magt, da regeringen skal have tillid fra parlamentet for at kunne fungere. Dette betyder, at parlamentet, i form af Folketinget, kan afsætte regeringen gennem et mistillidsvotum, hvis det mener, at regeringen ikke længere handler i overensstemmelse med folkets vilje. Domstolene forbliver dog uafhængige af de to andre magtorganer, hvilket sikrer en retfærdig og neutral dømmende magt, som kan gribe ind, hvis regeringen eller parlamentet handler i strid med loven eller forfatningen. Denne magtfordeling skal sikre en balance, hvor ingen af magtorganerne kan dominere de andre uden kontrol.`
    }
];

const ScanExamples = () => {
    const { t, i18n } = useTranslation(['dashboard']);
    const { classes } = useStyles();

    const [value, setValue] = useState('gpt-3.5');

    const getExample = (value) => {
        if (i18n?.language === "nb-NO" || i18n?.language === "nb") return scanExampleDataNB.find((sc) => sc.value === value);
        if (i18n?.language === "se") return scanExampleDataSE.find((sc) => sc.value === value);
        if (i18n?.language === "dk") return scanExampleDataDk.find((sc) => sc.value === value);

        return scanExampleDataEn.find((sc) => sc.value === value)
    }

    useEffect(() => { }, [i18n])

    return (
        <div className={style.wrapper}>

            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                <div>
                    <SegmentedControl
                        radius="xl"
                        size="md"
                        value={value}
                        onChange={setValue}
                        data={[
                            { label: 'ChatGPT', value: "gpt-3.5" },
                            { label: 'GPT-4', value: "gpt-4" },
                            { label: `${t('example.human')} 1`, value: "human-1" },
                            { label: `${t('example.human')} 2`, value: "human-2" },
                            { label: `${t('example.human')} 3`, value: "human-3" },
                            { label: t('example.mixed'), value: "mixed" }
                        ]}
                        classNames={classes}
                    />
                    <br />
                </div>
            </MediaQuery>

            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                <div className={style.selectWrapper}>
                    <Select
                        label="Velg eksempel"
                        value={value}
                        onChange={setValue}
                        data={[
                            { label: 'ChatGPT', value: "gpt-3.5" },
                            { label: 'GPT-4', value: "gpt-4" },
                            { label: `${t('example.human')} 1`, value: "human-1" },
                            { label: `${t('example.human')} 2`, value: "human-2" },
                            { label: `${t('example.human')} 3`, value: "human-3" },
                            { label: t('example.mixed'), value: "mixed" }
                        ]}
                    />
                </div>
            </MediaQuery>

            <br />
            <Text fs="italic" >{getExample(value).description}</Text>
            <hr />

            <div className={style.paragraphWrapperAI}>
                <div className={style.resultBoxMobile}>
                    <InfoSlabMobile aiScore={getExample(value).aiScore} alwaysVisible={true} />
                </div>
                {getExample(value).aiScore >= 0.99 ?
                    <pre className={`${style.text}`}>
                        <mark className={style.mark}>{getExample(value).text}</mark>
                    </pre>
                    : <pre className={`${style.text}`}>
                        {getExample(value).text}
                    </pre>
                }
                <div className={style.resultBox}>
                    <InfoSlab aiScore={getExample(value).aiScore} />
                </div>
            </div>

            {/* <textarea className="textarea" value={getExample(value).text}></textarea>
            <InfoSlab aiScore={getExample(value).aiScore} /> */}
        </div>
    )
}

export default ScanExamples;