import { useEffect, useRef, useState } from "react";
import Countdown from "react-countdown";

import style from "./feedback.module.css";
import { Button, Divider, Flex, Grid, Text, UnstyledButton } from "@mantine/core";

import checkmark from "./../../../../assets/checkmark.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";
import { IconArrowsDiagonal, IconArrowsDiagonalMinimize2 } from "@tabler/icons-react";

const FeedbackTab = ({ scan, auth, firestore, contentScanId, feedbackLoading, scrollWrapperRef, feedbackType }) => {
    const { t } = useTranslation(["common"]);

    const splittingRegex = /[.]+/;

    const feedbackTypeRefined =
        feedbackType === "factCheck"
            ? "factCheck"
            : feedbackType === "languageCheck"
            ? "languageCheck"
            : feedbackType === "languageImprovements"
            ? "languageImprovements"
            : "old";

    const [scrollPosition, setScrollPosition] = useState(0);

    // Function to update the scroll position state
    const handleScroll = () => {
        const position = scrollWrapperRef.current.scrollTop;
        setScrollPosition(position);
    };

    // Use effect to add and remove the event listener
    useEffect(() => {
        const divElement = scrollWrapperRef.current;
        divElement.addEventListener("scroll", handleScroll);

        // Cleanup function to remove the event listener
        return () => {
            divElement.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [sortedSpecificFeedback, setSortedSpecificFeedback] = useState([]);
    const [unspecificFeedback, setUnspecificFeedback] = useState([]);
    const preRef = useRef(null);

    const [indexOfHoveredSentence, setIndexOfHoveredSentence] = useState(null);

    const [elements, setElements] = useState([]);
    const [commentElements, setCommentElements] = useState([]);

    const getCommentsArray = (scan) => {
        switch (feedbackTypeRefined) {
            case "factCheck":
                return scan.factCheckComments ? scan.factCheckComments : scan.feedbackParagraphs ? scan.feedbackParagraphs[0]?.factCheckComments ?? [] : [];
            case "languageCheck":
                return scan.languageCheckComments
                    ? scan.languageCheckComments
                    : scan.feedbackParagraphs
                    ? scan.feedbackParagraphs[0]?.languageCheckComments ?? []
                    : [];
            case "languageImprovements":
                return scan.languageImprovementComments
                    ? scan.languageImprovementComments
                    : scan.feedbackParagraphs
                    ? scan.feedbackParagraphs[0]?.languageImprovementComments ?? []
                    : [];
            default:
                return scan.feedbackParagraphs ? scan.feedbackParagraphs[0]?.feedback ?? [] : [];
        }
    };

    const getTitle = () => {
        switch (feedbackTypeRefined) {
            case "factCheck":
                return t("scan.feedback.fact-check");
            case "languageCheck":
                return t("scan.feedback.language-check");
            case "languageImprovements":
                return t("scan.feedback.language-improvements");
            default:
                return t("scan.feedback.tab-title");
        }
    };

    const ignoreFeedback = async (feedbackItem) => {
        const copyOfFeedbackParagraphs = [...scan.feedbackParagraphs];

        //Modify specific feedbackItem status
        const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfFeedbackParagraphs[0].feedback];
        copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
            ...feedbackItem,
            status: "ignored",
        };

        //Add modified feedbackItem to feedback-array of feedbackParagraphs[0]
        copyOfFeedbackParagraphs[0] = {
            ...copyOfFeedbackParagraphs[0],
            feedback: copyOfFeedbackParagraphsZerothIndexFeedback,
        };

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId);
        await contentScanRef.update({
            feedbackParagraphs: copyOfFeedbackParagraphs,
        });
    };

    const toggleMinimizeFeedback = async (feedbackItem) => {
        const commentArray = [...getCommentsArray(scan)];

        //Modify specific feedbackItem status
        commentArray[feedbackItem.index] = {
            ...feedbackItem,
            status: feedbackItem.status === "minimized" ? "undecided" : "minimized",
        };

        const contentScanRef = firestore.collection("content-scan").doc(contentScanId);

        switch (feedbackTypeRefined) {
            case "factCheck":
                await contentScanRef.update({
                    factCheckComments: commentArray,
                });
                break;
            case "languageCheck":
                await contentScanRef.update({
                    languageCheckComments: commentArray,
                });
                break;
            case "languageImprovements":
                await contentScanRef.update({
                    languageImprovementComments: commentArray,
                });
                break;
        }
    };

    useEffect(() => {
        let fullFeedback = [];

        scan?.feedbackParagraphs &&
            scan.feedbackParagraphs[0] &&
            getCommentsArray(scan).forEach((feedbackItem) => {
                const { startIndex, endIndex, found } = findTextIndices(scan.feedbackParagraphs[0].text, feedbackItem.applicableSentences);

                if (feedbackItem.status !== "ignored") {
                    fullFeedback.push({
                        ...feedbackItem,
                        startIndex: found ? startIndex : null,
                        endIndex: found ? endIndex : null,
                    });
                }
            });

        const specificFeedback = fullFeedback.filter((fullFeedbackItem) => fullFeedbackItem.startIndex !== null && fullFeedbackItem.endIndex !== null);
        setUnspecificFeedback(fullFeedback.filter((fullFeedbackItem) => fullFeedbackItem.startIndex === null || fullFeedbackItem.endIndex === null) ?? []);

        setSortedSpecificFeedback(specificFeedback.sort((a, b) => a.startIndex - b.startIndex));
    }, [scan]);

    //Add highlights to text for feedbackItems
    useEffect(() => {
        const originalText = scan.text;

        // Now, when rendering, you account for the possible displacement caused by earlier comments
        let currentOffset = 0;
        const elements = sortedSpecificFeedback.reduce((acc, comment) => {
            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, comment.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment itself
            acc.push(
                <mark
                    key={comment.startIndex}
                    data-feedback-index={comment.index}
                    onMouseEnter={() => setIndexOfHoveredSentence(comment.index)}
                    onMouseLeave={() => setIndexOfHoveredSentence(null)}
                    className={indexOfHoveredSentence == comment.index ? style.markFocused : style.mark}
                >
                    {originalText.slice(comment.startIndex, comment.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = comment.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
    }, [sortedSpecificFeedback, setIndexOfHoveredSentence, indexOfHoveredSentence]);

    const isInSidebarView = useMediaQuery("(min-width: 1334px)");

    //Add comments based of <mark> tags in text
    useEffect(() => {
        const newCommentElements = [];
        const commentPositions = [];

        if (preRef.current) {
            const marks = preRef.current.querySelectorAll("mark");

            marks.forEach((mark, index) => {
                const feedbackIndex = mark.getAttribute("data-feedback-index");
                const feedbackItem = sortedSpecificFeedback.find((item) => item.index == feedbackIndex);

                const position = mark.getBoundingClientRect();

                if (feedbackItem) {
                    const TOP_OFFSET = isInSidebarView ? 170 : 350;

                    // Determine initial top position
                    let topPosition = position.top + scrollPosition > TOP_OFFSET ? position.top + scrollPosition - TOP_OFFSET : 0;
                    // let topPosition = position.top + scrollPosition;

                    // Create an off-screen div to measure the comment height
                    const offscreenDiv = document.createElement("div");
                    offscreenDiv.className = style.comment;
                    offscreenDiv.style.position = "absolute";
                    offscreenDiv.style.fontSize = "15px";
                    offscreenDiv.style.padding = " 0.25rem 0.5rem";
                    offscreenDiv.style.left = "-9999px"; // position off-screen
                    offscreenDiv.innerText = feedbackItem.comment;
                    document.body.appendChild(offscreenDiv);

                    const getHeightOfReferences = () => {
                        if (!feedbackItem.sources) return 50;
                        return 60 + feedbackItem.sources.length * 30;
                    };

                    // Measure the comment height
                    const commentHeight = feedbackItem.status === "minimized" ? 60 : offscreenDiv.offsetHeight + getHeightOfReferences();

                    // Remove the off-screen div
                    document.body.removeChild(offscreenDiv);

                    // Check for overlaps and adjust if necessary
                    for (const existingPosition of commentPositions) {
                        if (
                            (topPosition >= existingPosition.start && topPosition <= existingPosition.end) ||
                            (topPosition + commentHeight >= existingPosition.start && topPosition <= existingPosition.end)
                        ) {
                            topPosition = existingPosition.end + 5; // adjust by 5px gap
                        }
                    }

                    // Store the finalized position and height
                    commentPositions.push({ start: topPosition, end: topPosition + commentHeight });

                    // Create the JSX element for the comment
                    const commentElement = (
                        <div
                            key={index}
                            style={{
                                position: "absolute",
                                top: `${topPosition}px`,
                            }}
                            className={`${indexOfHoveredSentence == feedbackIndex ? style.commentFocused : style.comment} ${
                                feedbackItem.status === "minimized" ? style.commentHeightMinimzed : style.commentHeightFull
                            }`}
                            onMouseEnter={() => setIndexOfHoveredSentence(feedbackIndex)}
                            onMouseLeave={() => setIndexOfHoveredSentence(null)}
                        >
                            <div className={style.commentContent}>
                                <p style={{ fontSize: "15px" }}>{feedbackItem.comment}</p>

                                {feedbackItem.sources && feedbackItem.sources.length > 0 && (
                                    <div className={style.commentSourceWrapper}>
                                        <Text fz="sm">{t("scan.feedback.sources")}</Text>
                                        {feedbackItem.sources.map((source, index) => (
                                            <div className={style.commentSource}>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className={style.commentLink}
                                                    href={source.link}
                                                    dangerouslySetInnerHTML={{ __html: `${index + 1}. ${formatSource(source.htmlTitle)}` }}
                                                ></a>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <button className={style.minimizeButton} onClick={() => toggleMinimizeFeedback(feedbackItem)}>
                                <Flex justify={"center"} align={"center"}>
                                    {feedbackItem.status === "minimized" ? (
                                        <IconArrowsDiagonal color="var(--text-off)" size={"1.25rem"} />
                                    ) : (
                                        <IconArrowsDiagonalMinimize2 color="var(--text-off)" size={"1.25rem"} />
                                    )}
                                </Flex>
                            </button>

                            {/* {feedbackItem.status === "added" ?
                                <div className={style.commentAddedText}>
                                    <Flex columnGap={"0.3rem"} align={"center"}>
                                        <img className={style.checkmark} src={checkmark} alt=""></img>
                                        <span>{t('scan.feedback.added-as-comment')}</span>
                                    </Flex>
                                </div>
                                :
                                <div className={style.commentButtonRow}>
                                    { <UnstyledButton onClick={() => addFeedbackAsComment(feedbackItem)} className="plausible-event-name=addFeedback">
                                        <div className={style.commentButtonAdd}>{`+ ${t('scan.feedback.add-as-comment')}`}</div>
                                    </UnstyledButton>}
                                    <UnstyledButton onClick={() => ignoreFeedback(feedbackItem)} className="plausible-event-name=ignoreFeedback">
                                        <div className={style.commentButtonIgnore}>{t('scan.feedback.ignore')}</div>
                                    </UnstyledButton>
                                </div>} */}
                        </div>
                    );

                    newCommentElements.push(commentElement);
                }
            });
        }

        setCommentElements(newCommentElements);
    }, [elements, indexOfHoveredSentence]);

    return (
        <div>
            {feedbackLoading ? (
                <div>
                    <p>{t("scan.feedback.waiting-message")}</p>
                    {scan.expectedFeedbackFinishedTime && <Countdown date={new Date(scan.expectedFeedbackFinishedTime.seconds * 1000)} daysInHours />}
                </div>
            ) : (
                <div>
                    <div className={style.titleWrapper}>
                        <h4>{getTitle()}</h4>
                    </div>

                    <div className={style.wrapper}>
                        <div id="text-container" className={style.text} ref={preRef} style={{ whiteSpace: "pre-line" }}>
                            {elements}
                        </div>

                        <div className={style.sideWrapper}>
                            <div className={style.commentListWrapper}>
                                <div className={style.commentList}>{commentElements}</div>
                            </div>
                        </div>
                    </div>

                    {unspecificFeedback.length > 0 && (
                        <div className={style.wrapperGeneralFeedback}>
                            <Divider mt={"4rem"} />
                            <h4>{t("scan.feedback.general-suggestions")}</h4>

                            {unspecificFeedback.map((feedbackItem) => (
                                <div className={style.generalFeedback}>
                                    <Grid gutter={"2rem"}>
                                        <Grid.Col span={9}>
                                            <Text>{feedbackItem.comment}</Text>

                                            {feedbackItem.sources && feedbackItem.sources.length > 0 && (
                                                <div className={style.commentSourceWrapper}>
                                                    <Text fz="sm">{t("scan.feedback.sources")}</Text>
                                                    {feedbackItem.sources.map((source, index) => (
                                                        <div className={style.commentSource}>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className={style.commentLink}
                                                                href={source.link}
                                                                dangerouslySetInnerHTML={{ __html: `${index + 1}. ${formatSource(source.htmlTitle)}` }}
                                                            ></a>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </Grid.Col>

                                        <Grid.Col span={3}>
                                            {feedbackItem.status === "added" ? (
                                                <Flex columnGap={"0.3rem"} align={"center"} w={"15rem"}>
                                                    <img className={style.checkmark} src={checkmark} alt=""></img>
                                                    <span>{t("scan.feedback.added")}</span>
                                                </Flex>
                                            ) : (
                                                <Flex direction={"column"} rowGap={"0.5rem"}>
                                                    {/* <Button onClick={() => addToGeneralFeedback(feedbackItem)} color="teal" className="plausible-event-name=addFeedback">{`+ ${t('common.add')}`}</Button> */}
                                                    <Button
                                                        onClick={() => ignoreFeedback(feedbackItem)}
                                                        color="gray"
                                                        variant="light"
                                                        className="plausible-event-name=ignoreFeedback"
                                                    >
                                                        {t("scan.feedback.ignore")}
                                                    </Button>
                                                </Flex>
                                            )}
                                        </Grid.Col>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FeedbackTab;

const formatSource = (string) => {
    return string.replace(/<\/?b>/g, "");
};

function findTextIndices(fullText, searchText) {
    const startIndex = fullText.indexOf(searchText);
    const endIndex = startIndex + searchText.length;

    if (startIndex === -1) {
        return { startIndex: -1, endIndex: -1, found: false };
    }

    return { startIndex, endIndex, found: true };
}

function isCommentCollision(newComment, commentsArray) {
    for (let comment of commentsArray) {
        if (newComment.startIndex < comment.endIndex && newComment.endIndex > comment.startIndex) {
            return true; // There is a collision
        }
    }
    return false;
}

// const addFeedbackAsComment = async (feedbackItem) => {

//     if (isCommentCollision(feedbackItem, scan.comments)) return;

//     const copyOfComments = [...scan.comments];
//     const copyOfFeedbackParagraphs = [...scan.feedbackParagraphs];

//     //Modify specific feedbackItem status
//     const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfFeedbackParagraphs[0].feedback];
//     copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
//         ...feedbackItem,
//         status: "added",
//     }

//     //Add modified feedbackItem to feedback-array of feedbackParagraphs[0]
//     copyOfFeedbackParagraphs[0] = {
//         ...copyOfFeedbackParagraphs[0],
//         feedback: copyOfFeedbackParagraphsZerothIndexFeedback
//     }

//     copyOfComments.push({
//         relevantText: feedbackItem.applicableSentences,
//         comment: feedbackItem.comment,
//         startIndex: feedbackItem.startIndex,
//         endIndex: feedbackItem.endIndex,
//         authorName: auth.currentUser.displayName,
//         authorEmail: auth.currentUser.email,
//     })

//     const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
//     await contentScanRef.update({
//         comments: copyOfComments,
//         feedbackParagraphs: copyOfFeedbackParagraphs,
//     })
// }

// const addToGeneralFeedback = async (feedbackItem) => {
//     let currentGeneralFeedback = scan.generalFeedback ?? "";

//     //Add text to generalFeedback
//     if (scan.generalFeedback) currentGeneralFeedback += "\n\n";
//     currentGeneralFeedback += feedbackItem.comment;

//     //Update feedbackItem status
//     const copyOfFeedbackParagraphs = [...scan.feedbackParagraphs];

//     const copyOfFeedbackParagraphsZerothIndexFeedback = [...copyOfFeedbackParagraphs[0].feedback];
//     copyOfFeedbackParagraphsZerothIndexFeedback[feedbackItem.index] = {
//         ...feedbackItem,
//         status: "added",
//     }

//     copyOfFeedbackParagraphs[0] = {
//         ...copyOfFeedbackParagraphs[0],
//         feedback: copyOfFeedbackParagraphsZerothIndexFeedback
//     }

//     const contentScanRef = firestore.collection("content-scan").doc(contentScanId)
//     await contentScanRef.update({
//         generalFeedback: currentGeneralFeedback,
//         feedbackParagraphs: copyOfFeedbackParagraphs,
//     })
// }
