
import { Divider, NavLink, Stack, Title } from "@mantine/core";
import ScanChatbot from "../scan-chatbot/scan-chatbot";
import style from "./sidebar.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import starsIcon from './../../../../assets/stars.svg'
import suggestedCommentIcon from './../../../../assets/suggestedComment.svg'
import highlightIcon from './../../../../assets/highlight.svg'
import useGetSubscription from "../../../../hooks/useGetSubscription";


const Sidebar = ({ firebase, scan, tabs, tab, setTab, highlightIndex, setHighlightIndex, generatedCommentIndex, setGeneratedCommentIndex }) => {
    const { t } = useTranslation(['common']);

    const { isProfessional, isTrial } = useGetSubscription();

    const [showChat, setShowChat] = useState(false);

    return (
        <div className={style.sidebar}>
            <div className={showChat ? style.navigationWrapperMinimized : style.navigationWrapper}>

                <Title my={"0.75rem"} ml={"0.75rem"} fw={600} order={6}>{scan.name}</Title>
                <Divider my={"0.5rem"}/>

                <Stack spacing={"0rem"} style={{ overflowY: "auto", height: "100%" }}>
                    {tabs.map((tabElement) => {
                        if(tabElement.type === "divider" && !tabElement.disabled) return <Divider my={"0.5rem"} />
                        if (tabElement.dependencyStatement) return (
                            <NavLink
                                icon={tabElement.icon}
                                label={(tabElement.number || tabElement.number === 0) ? `${tabElement.title} 
                                (${tabElement.number})` : tabElement.title}
                                active={tabElement.name === tab}
                                fw={tabElement.name === tab ? 500 : 500}
                                onClick={() => setTab(tabElement.name)}
                                disabled={tabElement.disabled}
                                className={tabElement.analyticsId ? `plausible-event-name=${tabElement.analyticsId}` : ""}
                            />
                        )
                    })}

                    {(scan?.customHighlights?.length > 0 || scan?.generatedComments?.length > 0) &&
                        <NavLink
                            fw={500}
                            label={t("scan.copilot.from_copilot_link")}
                            defaultOpened
                            icon={<img src={starsIcon} style={{ width: "1rem", height: "1rem" }} />}
                        >
                            {scan?.customHighlights?.map((highlight, index) => (
                                <NavLink
                                    label={highlight?.name}
                                    active={tab === "highlights" && highlightIndex === highlight.id}
                                    fw={(tab === "highlights" && highlightIndex === highlight.id) ? 600 : 500}
                                    onClick={() => { setTab("highlights"); setHighlightIndex(highlight.id) }}
                                    icon={<img src={highlightIcon} style={{ width: "1rem", height: "0.8rem" }} />}
                                />
                            ))}

                            {scan?.generatedComments?.map((generatedComments, index) => (
                                <NavLink
                                    label={generatedComments?.name}
                                    active={tab === "generatedComments" && generatedCommentIndex === generatedComments.id}
                                    fw={(tab === "generatedComments" && generatedCommentIndex === generatedComments.id) ? 600 : 500}
                                    onClick={() => { setTab("generatedComments"); setGeneratedCommentIndex(generatedComments.id) }}
                                    icon={<img src={suggestedCommentIcon} style={{ width: "1rem", height: "0.8rem" }} />}
                                />
                            ))}
                        </NavLink>
                    }
                </Stack>
            </div>

            {/* {(isProfessional || isTrial) && <ScanChatbot
                firebase={firebase}
                scan={scan}
                showChat={showChat}
                setShowChat={setShowChat}
                setTab={setTab}
                setHighlightIndex={setHighlightIndex}
                setGeneratedCommentIndex={setGeneratedCommentIndex}
            />} */}
        </div >
    )
}

export default Sidebar;