import { ColorSwatch, Flex, List, Stack, Text, Tooltip } from "@mantine/core";
import style from "./modes-of-persuasion.module.css";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconInfoCircle, IconInfoCircleFilled } from "@tabler/icons-react";

const ModesOfPersuasion = ({ scan, modesOfPersuasion }) => {
    const { t } = useTranslation(["common"]);

    const preRef = useRef(null);
    const [elements, setElements] = useState([]);

    // const [correctlyUsedTerms, setCorrectlyUsedTerms] = useState([]);
    // const [mixedUsedTerms, setMixedUsedTerms] = useState([]);
    // const [wronglyUsedTerms, setWronglyUsedTerms] = useState([]);

    const modesOfPersuasionSorted = [...modesOfPersuasion];
    // modesOfPersuasionSorted.sort((a, b) => b.term?.length - a.term?.length); //Sort in decending order from longest to shortest string in order to avoid a shorter and worst match taking the place of a larger one

    const numberOfLogos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "logos").length;
    const numberOfPathos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "pathos").length;
    const numberOfEthos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "ethos").length;
    const numberOfKairos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "kairos").length;
    const numberOfTopos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "topos").length;
    const numberOfMythos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "mythos").length;
    const numberOfTelos = modesOfPersuasionSorted.filter((term) => term.modeOfPersuasion === "telos").length;

    useEffect(() => {
        console.log(modesOfPersuasionSorted);

        const originalText = scan.text;
        let modesOfPersuasionEntries = [];
        let coveredIndices = new Set(); // Keep track of covered indices to avoid overlaps

        modesOfPersuasionSorted.forEach((modeOfPersuasionEntry) => {
            // Skip if applicableSentences is empty or undefined
            if (!modeOfPersuasionEntry.applicableSentences || modeOfPersuasionEntry.applicableSentences.trim() === "") {
                return;
            }

            const escapedSentence = modeOfPersuasionEntry.applicableSentences.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
            const regex = new RegExp(escapedSentence, "g");

            let match;

            while ((match = regex.exec(originalText)) !== null) {
                const matchStartIndex = match.index;
                const matchEndIndex = matchStartIndex + modeOfPersuasionEntry.applicableSentences?.length;
                let isOverlapping = false;

                // Check if any part of the current match is already covered
                for (let i = matchStartIndex; i < matchEndIndex; i++) {
                    if (coveredIndices.has(i)) {
                        isOverlapping = true;
                        break;
                    }
                }

                console.log(isOverlapping);

                if (!isOverlapping) {
                    // If not overlapping, add to terms and mark indices as covered
                    modesOfPersuasionEntries.push({
                        applicableSentences: modeOfPersuasionEntry.applicableSentences,
                        modeOfPersuasion: modeOfPersuasionEntry.modeOfPersuasion,
                        startIndex: matchStartIndex,
                        endIndex: matchEndIndex,
                    });
                    for (let i = matchStartIndex; i < matchEndIndex; i++) {
                        coveredIndices.add(i);
                    }
                }
            }
        });

        // Sort comments by their 'startIndex'
        modesOfPersuasionEntries = modesOfPersuasionEntries.sort((a, b) => a.startIndex - b.startIndex);
        console.log(modesOfPersuasionEntries);

        let currentOffset = 0;
        const elements = modesOfPersuasionEntries.reduce((acc, term, index) => {
            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, term.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            const getClassName = (modeOfPersuasion) => {
                switch (modeOfPersuasion) {
                    case "logos":
                        return style.markLogos;
                    case "ethos":
                        return style.markEthos;
                    case "pathos":
                        return style.markPathos;
                    case "kairos":
                        return style.markKairos;
                    case "topos":
                        return style.markTopos;
                    case "mythos":
                        return style.markMythos;
                    case "telos":
                        return style.markTelos;
                }
            };

            // Add the comment or technical term
            console.log(term);

            acc.push(
                <mark key={term.startIndex} data-comment={term.term} data-index={index} className={getClassName(term.modeOfPersuasion)}>
                    {originalText.slice(term.startIndex, term.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = term.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
    }, [modesOfPersuasion]);

    return (
        <div>
            <div className={style.titleWrapper}>
                <h4>{t("scan.feedback.modes-of-persuasion.title")}</h4>
            </div>
            <div className={style.wrapper}>
                <div id="text-container" className={style.text} ref={preRef} style={{ whiteSpace: "pre-line" }}>
                    {elements}
                </div>
                <div className={style.legend}>
                    <Stack spacing={"0.5rem"}>
                        <Text fw={600}>{t("scan.feedback.modes-of-persuasion.label")}</Text>

                        {numberOfLogos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(199, 236, 255)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.logos.name")} (${numberOfLogos})`}</Text>

                                {/* <Tooltip zIndex={100000} styles={{tooltip: {width: "20rem"}}} label={t("scan.feedback.modes-of-persuasion.logos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfPathos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(255, 226, 199)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.pathos.name")} (${numberOfPathos})`}</Text>

                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.pathos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfEthos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(220, 199, 255)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.ethos.name")} (${numberOfEthos})`}</Text>

                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.ethos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfKairos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(199, 201, 255)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.kairos.name")} (${numberOfKairos})`}</Text>

                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.kairos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfTopos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(199, 255, 221)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.topos.name")} (${numberOfTopos})`}</Text>

                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.topos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfMythos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(255, 199, 255)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.mythos.name")} (${numberOfMythos})`}</Text>

                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.mythos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}

                        {numberOfTelos > 0 && (
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch color="rgb(255, 199, 199)" size={"0.8rem"} />
                                <Text>{`${t("scan.feedback.modes-of-persuasion.telos.name")} (${numberOfTelos})`}</Text>
                                {/* <Tooltip label={t("scan.feedback.modes-of-persuasion.telos.explaination")} withArrow>
                                    <IconInfoCircle color="var(--greyer)" size={"1.125rem"} />
                                </Tooltip> */}
                            </Flex>
                        )}
                    </Stack>
                </div>
            </div>
        </div>
    );
};

export default ModesOfPersuasion;
