import { Button, Card, Flex, List, Loader, Text, Title } from "@mantine/core";
import Page from "../../molecules/page/page"
import style from "./wait-list.module.css"
import { loadStripe } from '@stripe/stripe-js';
import { useContext, useEffect, useState } from "react";
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PricingLoader from "../../molecules/pricing-loader";
import useGetSubscription from "../../hooks/useGetSubscription";
import { priceIdFlex, priceIdProfessional } from "../../contexts/priceIdVariables";

const WaitList = ({ firebase, auth, firestore }) => {
    const { user, organization, loading } = useContext(UserAndOrganzationContext);
    const { subscriptionType, subscriptionFromOrganization } = useGetSubscription();

    const { t } = useTranslation(['common']);
    const navigate = useNavigate();

    const [loading0, setLoading0] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const handleLoadingState = (subscriptionNumber, newState) => {
        switch (subscriptionNumber) {
            case 0: setLoading0(newState); break;
            case 1: setLoading1(newState); break;
            case 2: setLoading2(newState); break;
        }
    }

    const redirectToCheckout = async (priceId, subscriptionNumber, isTrial) => {
        handleLoadingState(subscriptionNumber, true)

        // Replace with your Stripe publishable key
        const stripe = await loadStripe('pk_live_51NTOXIKwREFQyFmXRZDU7ohPjdtDGzYxjN4rW7VsQRP5GUjFss8ljK9He5LAIkmcvFU7KfEYFai3QZWtKABUPhlu000FG2ds5E');

        const sessionId = await createCheckoutSession(priceId, isTrial);

        if (sessionId) {
            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                console.error('Error redirecting to checkout:', error);
            }
        }

        handleLoadingState(subscriptionNumber, false)
    };


    const createCheckoutSession = async (priceId, isTrial) => {
        const functions = firebase.app().functions("europe-west1");
        const createCheckoutSessionQuery = functions.httpsCallable('checkoutSession');
        const hostname = window.location.hostname;

        try {
            const result = await createCheckoutSessionQuery({ priceId, isTrial, hostname });
            return result.data.sessionId;
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    useEffect(() => {
        if (subscriptionType && !subscriptionFromOrganization && subscriptionType !== "waiting") {
            navigate("/");
        }
    }, [subscriptionType])

    if (loading) return (
        <Page grey>
            <Loader></Loader>
        </Page>
    )

    return (
        <Page grey>

            <PricingLoader opened={loading0 || loading1 || loading2} text={t("upgrade.loading-label-trial")}/>

            <div className={style.wrapper}>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <h2 >{t("upgrade.title-trial")}</h2>
                </div>
                
                <div className={style.grid}>
                    <Card shadow="md" className={style.gridItem}>

                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.flex.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.flex.scan-limit")}</List.Item>
                                    <List.Item>{t("upgrade.flex.word-limit")}</List.Item>
                                    <List.Item>{t("upgrade.flex.ai-scan")}</List.Item>
                                    <List.Item>{t("upgrade.flex.follow-up-questions")}</List.Item>
                                </List>

                            </div>

                            <br />
                            <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.flex.price")}</Text>
                            <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.flex.billing-frequency")}</Text>

                            {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting") ?
                                <Button className={"plausible-event-name=trial-flex"} variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout(priceIdFlex, 0, true)} loading={loading2}>{t("upgrade.start-7-day-trial")}</Button>
                                :
                                <Button variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} loading={true}>{t("upgrade.start-7-day-trial")}</Button>
                            }
                        </Card.Section>
                    </Card>

                    {/* <Card shadow="md" className={style.gridItem}>
                        <Card.Section bg={"white"} py={"0.25rem"}>
                            <Flex justify={"center"}>
                                <Title fw={500} fz={"1rem"} c={"white"}>-</Title>
                            </Flex>
                        </Card.Section>

                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.premium.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.premium.scan-limit")}</List.Item>
                                    <List.Item>{t("upgrade.premium.word-limit")}</List.Item>
                                    <List.Item>{t("upgrade.premium.ai-scan")}</List.Item>
                                    <List.Item>{t("upgrade.premium.follow-up-questions")}</List.Item>
                                </List>

                            </div>

                            <br />
                            <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.premium.price")}</Text>
                            <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.premium.billing-frequency")}</Text>

                            {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting") ?
                                <Button className={"plausible-event-name=trial-premium"} variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout("price_1OZIT7KwREFQyFmXF4tBSgkN", 0, true)} loading={loading0}>{t("upgrade.start-7-day-trial")}</Button>
                                :
                                <Button variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} loading={true}>{t("upgrade.start-7-day-trial")}</Button>
                            }
                        </Card.Section>
                    </Card> */}

                    <Card shadow="md" className={style.gridItemHighlighted}>
                        <Card.Section bg={"dark"} py={"0.25rem"} h={"2rem"}>
                            <Flex justify={"center"} align={"center"}>
                                <Title fw={500} fz={"1rem"} c={"white"}>{t("upgrade.most-popular")}</Title>
                            </Flex>
                        </Card.Section>

                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.pro.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.pro.scan-limit")}</List.Item>
                                    <List.Item>{t("upgrade.pro.word-limit")}</List.Item>
                                    <List.Item>{t("upgrade.pro.ai-scan")}</List.Item>
                                    <List.Item>{t("upgrade.pro.follow-up-questions")}</List.Item>
                                    <List.Item>{t("upgrade.pro.fact-check")}</List.Item>
                                    <List.Item>{t("upgrade.pro.language-check")}</List.Item>
                                    {/* <List.Item>{t("upgrade.pro.copilot")}</List.Item> */}
                                    <List.Item>{t("upgrade.pro.text-analysis")}</List.Item>     
                                    <List.Item>{t("upgrade.pro.technical-terms")}</List.Item>                               
                                </List>
                            </div>

                            <br />
                            <Flex columnGap={"0.5rem"} align={"baseline"}>
                                <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.pro.price")}</Text>
                                {/* <Text color="red" style={{ textDecoration: "line-through" }} fz={"1.25rem"} fw={"500"}>{t("upgrade.pro.price")}</Text> */}
                                {/* <Text fz={12} color="red">{`${daysUntil("2024-09-30")} ${t("upgrade.pro.days-left")}`}</Text> */}
                            </Flex>
                            <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.pro.billing-frequency")}</Text>

                            {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting") ?
                                <Button className={"plausible-event-name=trial-full-package"} color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => redirectToCheckout(priceIdProfessional, 1, true)} loading={loading1}>{t("upgrade.start-7-day-trial")}</Button>
                                :
                                <Button color={"dark"} miw={"100%"} mt={"1rem"} loading={true}>{t("upgrade.start-7-day-trial")}</Button>
                            }
                        </Card.Section>
                    </Card>

                    <Card shadow="md" className={style.gridItem}>
                        <Card.Section px={"2rem"} pt={"1rem"} pb={"2rem"}>
                            <div className={style.gridItemTopContent}>
                                <Text fz={"1.3rem"} fw={"600"}>{t("upgrade.schools.name")}</Text>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.schools.all-of-the-above")}</List.Item>
                                    <List.Item>{t("upgrade.schools.dpa")}</List.Item>
                                </List>
                                <br />
                                <List>
                                    <List.Item>{t("upgrade.schools.free-trial")}</List.Item>
                                    <List.Item>{t("upgrade.schools.multiple-users")}</List.Item>                                    
                                </List>
                            </div>                   <br />
                            <Text fz={"1.25rem"} fw={"500"}>{t("upgrade.schools.price")}</Text>
                            <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">{t("upgrade.schools.billing-frequency")}</Text>

                            {user?.userData?.type && (user.userData.type === "trial" || user.userData.type === "waiting") ?
                                <Button className={"plausible-event-name=trial-schools"} variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} onClick={() => navigate("/new-organization")} >{t("upgrade.schools.action-button")}</Button>
                                :
                                <Button variant="outline" color={"dark"} miw={"100%"} mt={"1rem"} loading={true}>{t("upgrade.schools.action-button")}</Button>
                            }
                        </Card.Section>
                    </Card>
                </div>
            </div>
        </Page>
    )
}

export default WaitList;

function daysUntil(date) {
    const today = new Date();
    const targetDate = new Date(date);

    // Calculate the difference in time
    const differenceInTime = targetDate.getTime() - today.getTime();

    // Calculate the difference in days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays;
}
