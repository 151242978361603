import { useContext, useEffect, useState } from "react"
import Page from "../../molecules/page/page"
import OrganizationMemberTable from "../../organisms/organizationMemberTable/organizationMemberTable"
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { Modal, Button, Input, CopyButton, Flex, Container, Text, Title } from '@mantine/core';
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";
import Spinner from "../../molecules/Spinner/spinner"
import useGetOrganizationMembers from "../../hooks/useGetOrganizationMembers";
import { useTranslation } from "react-i18next";
import EditSubscription from "./components/edit-subscription";
import useGetSubscription from "../../hooks/useGetSubscription";
import BuySubscription from "./components/buy-subscription";

import style from './organization.module.css';

const Organization = ({ firestore, auth, firebase }) => {
    const { t } = useTranslation(['common']);

    const { user, organization } = useContext(UserAndOrganzationContext);
    const { subscriptionType } = useGetSubscription();

    const [opened, { open, close }] = useDisclosure(false);

    const organizationMembers = useGetOrganizationMembers(firebase, organization?.organizationId)

    const isMobile = useMediaQuery("(max-width: 50em)");

    // const [name, setName] = useState("")
    const [invitationLink, setInvitationLink] = useState(null)
    const [loading, setLoading] = useState(false);

    const [errorInviteLink, setErrorInviteLink] = useState(null);

    const functions = firebase.app().functions("europe-west1");
    const createOrganizationInvitation = functions.httpsCallable("createOrganizationInvitation")

    const createInvitationQuery = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true)

        try {
            const result = await createOrganizationInvitation({ organizationId: organization.organizationId });
            const invitationId = result.data.id;
            const organizationName = result.data.organizationName;
            setLoading(false);
            setInvitationLink(`${window.location.origin}/join/${invitationId}/${encodeURIComponent(organizationName)}`);
        } catch (error) {
            setLoading(false);
            setErrorInviteLink(error.message)
        }
    }

    const openModal = (e) => {
        createInvitationQuery(e);
        open();
    }

    useEffect(() => {

    }, [organizationMembers])

    if (organization?.organizationData?.name) return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                transitionProps={{ transition: 'fade', duration: 200 }}
                fullScreen={isMobile}
                centered
                size="lg"
                title={<Title fw={600} order={5}>{t('organization.invite.title')}</Title>}
            >
                {loading ?
                    <Spinner />
                    :
                    errorInviteLink === "no-available-user-licences" ?
                        <Text>{t("organization.invite.no-available-user-licences-error")}</Text>
                        :
                        <Container px={"xl"}>
                            <p>
                                {t('organization.invite.description')}
                            </p>
                            <br />
                            <Flex
                                columnGap={"0.2rem"}
                            >
                                <Input
                                    value={invitationLink}
                                    style={{ width: '100%' }}
                                />
                                <CopyButton value={invitationLink}>
                                    {({ copied, copy }) => (
                                        <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                                            {copied ? t('organization.invite.copy-link-confirmation') : t('organization.invite.copy-link-button')}
                                        </Button>
                                    )}
                                </CopyButton>

                            </Flex>
                            <br />
                            <br />
                        </Container>
                }
            </Modal>

            <Page>
                <br />
                <label>{t('organization.title')}</label>
                <Flex justify={"space-between"} align={{base: "start", md: "center"}} direction={{base: "column", md: "row"}} >
                    <h3>{organization.organizationData.name}</h3>
                    
                    {organization?.organizationData?.admins && organization?.organizationData?.admins.includes(auth.currentUser.uid) ?
                        subscriptionType === "waiting" || subscriptionType === "cancelled" ?
                            <BuySubscription organizationId={organization.organizationId} organizationData={organization.organizationData} />
                            :
                            <Flex columnGap={"0.5rem"}>
                                <EditSubscription organizationId={organization.organizationId} organizationData={organization.organizationData} />
                                <Button color="dark" onClick={(e) => openModal(e)} disabled={organization?.organizationData.members.length >= organization?.organizationData.numberOfUserLicences}>
                                    {`+ ${t('organization.invite-members-button')}`}
                                </Button>
                            </Flex>
                        :
                        subscriptionType === "waiting" || subscriptionType === "cancelled" ?
                            <div className={style.warningWrapper}>
                                <Flex columnGap={"0.25rem"} align={"center"} maw={{ base: "10rem", md: "30rem" }}>
                                    <p>{`${t('new-scan.contact-organization')} ${t('new-scan.contact-organization-link')} ${t("new-scan.contact-organization-suffix")}`}</p>
                                </Flex>
                            </div>
                            :
                            <div />
                    }
                </Flex>

                <div style={{ overflow: "auto" }}>
                    <OrganizationMemberTable firebase={firebase} auth={auth} data={organizationMembers.members} loading={organizationMembers.loading} organization={organization} />
                </div>
            </Page>
        </>
    )

    return (
        <Page>
            <div style={{ margin: "auto", marginTop: "5rem" }}>
                <Spinner />
            </div>
        </Page>
    )
}

export default Organization;
