import { ColorSwatch, Flex, List, Stack, Text } from "@mantine/core";
import style from "./technical-terms-usage.module.css";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const TechnicalTermsUsage = ({ scan, technicalTerms }) => {
    const { t } = useTranslation(["common"]);

    const preRef = useRef(null);
    const [elements, setElements] = useState([]);

    // const [correctlyUsedTerms, setCorrectlyUsedTerms] = useState([]);
    // const [mixedUsedTerms, setMixedUsedTerms] = useState([]);
    // const [wronglyUsedTerms, setWronglyUsedTerms] = useState([]);

    const technicalTermsSorted = [...technicalTerms];
    technicalTermsSorted.sort((a, b) => b.term?.length - a.term?.length); //Sort in decending order from longest to shortest string in order to avoid a shorter and worst match taking the place of a larger one

    const correctlyUsedTerms = technicalTermsSorted.filter((term) => term.usage === "correct");
    const mixedUsedTerms = technicalTermsSorted.filter((term) => term.usage === "mixed");
    const wronglyUsedTerms = technicalTermsSorted.filter((term) => term.usage === "wrong");

    useEffect(() => {
        const originalText = scan.text;
        let terms = [];
        let coveredIndices = new Set(); // Keep track of covered indices to avoid overlaps

        technicalTermsSorted.forEach((technicalTerm) => {
            const regex = new RegExp(`\\b${technicalTerm.term}\\b`, "gi");
            let match;
            while ((match = regex.exec(originalText)) !== null) {
                const matchStartIndex = match.index;
                const matchEndIndex = matchStartIndex + technicalTerm.term?.length;
                let isOverlapping = false;

                // Check if any part of the current match is already covered
                for (let i = matchStartIndex; i < matchEndIndex; i++) {
                    if (coveredIndices.has(i)) {
                        isOverlapping = true;
                        break;
                    }
                }

                if (!isOverlapping) {
                    // If not overlapping, add to terms and mark indices as covered
                    terms.push({
                        term: technicalTerm.term,
                        usage: technicalTerm.usage,
                        isTechnicalTerm: true,
                        startIndex: matchStartIndex,
                        endIndex: matchEndIndex,
                    });
                    for (let i = matchStartIndex; i < matchEndIndex; i++) {
                        coveredIndices.add(i);
                    }
                }
            }
        });

        // Sort comments by their 'startIndex'
        terms = terms.sort((a, b) => a.startIndex - b.startIndex);

        let currentOffset = 0;
        const elements = terms.reduce((acc, term, index) => {
            // Extract text before the current comment
            const textBeforeComment = originalText.slice(currentOffset, term.startIndex);
            if (textBeforeComment) {
                acc.push(textBeforeComment);
            }

            // Add the comment or technical term
            acc.push(
                <mark
                    key={term.startIndex}
                    data-comment={term.term}
                    data-index={index}
                    className={term.usage === "correct" ? style.markGreen : term.usage === "wrong" ? style.markRed : style.markYellow}
                >
                    {originalText.slice(term.startIndex, term.endIndex)}
                </mark>
            );

            // Update offset to the end of the current comment
            currentOffset = term.endIndex;
            return acc;
        }, []);

        // Add any remaining text after the last comment
        const remainingText = originalText.slice(currentOffset);
        if (remainingText) {
            elements.push(remainingText);
        }

        setElements(elements);
    }, [technicalTerms]);

    return (
        <div>
            <div className={style.titleWrapper}>
                <h4>{t("scan.feedback.technical-terms")}</h4>
            </div>
            <div className={style.wrapper}>
                <div id="text-container" className={style.text} ref={preRef} style={{ whiteSpace: "pre-line" }}>
                    {elements}
                </div>
                <div>
                    {wronglyUsedTerms.length > 0 && (
                        <Stack spacing={"0.5rem"} mb={"2rem"}>
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch size={"0.8rem"} color="rgb(253, 218, 218)" />
                                <Text fw={600}>{t("scan.feedback.usage.wrong")}</Text>
                            </Flex>
                            <List size="sm">
                                {wronglyUsedTerms.map((term) => (
                                    <List.Item fz={14}>{term.term}</List.Item>
                                ))}
                            </List>
                        </Stack>
                    )}

                    {mixedUsedTerms.length > 0 && (
                        <Stack spacing={"0.5rem"} mb={"2rem"}>
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch size={"0.8rem"} color="rgb(255, 250, 182)" />
                                <Text fw={600}>{t("scan.feedback.usage.mixed")}</Text>
                            </Flex>
                            <List size="sm">
                                {mixedUsedTerms.map((term) => (
                                    <List.Item fz={14}>{term.term}</List.Item>
                                ))}
                            </List>
                        </Stack>
                    )}

                    {correctlyUsedTerms.length > 0 && (
                        <Stack spacing={"0.5rem"} mb={"2rem"}>
                            <Flex gap={"0.5rem"} align={"center"}>
                                <ColorSwatch size={"0.8rem"} color="rgb(199, 255, 199)" />
                                <Text fw={600}>{t("scan.feedback.usage.correct")}</Text>
                            </Flex>
                            <List size="sm">
                                {correctlyUsedTerms.map((term) => (
                                    <List.Item fz={14}>{term.term}</List.Item>
                                ))}
                            </List>
                        </Stack>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TechnicalTermsUsage;
