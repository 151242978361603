import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconButton from "../../molecules/IconButton/iconButton";
import Page from "../../molecules/page/page";
import Spinner from "../../molecules/Spinner/spinner";
import style from "./new-organization.module.css";
import { useTranslation } from "react-i18next";
import { Button, Divider, Flex, List, Loader, NumberInput, Slider, Stack, Tabs, Text, Title } from "@mantine/core";
import { UserAndOrganzationContext } from "../../contexts/userAndOrganizationContext";
import { loadStripe } from "@stripe/stripe-js";
import PricingLoader from "../../molecules/pricing-loader";
import useGetSubscription from "../../hooks/useGetSubscription";
import { priceIdOrganizationProfessional } from "../../contexts/priceIdVariables";

const NewOrganization = ({ firestore, auth, firebase }) => {
    const { t } = useTranslation(["common"]);

    const navigate = useNavigate();
    const { user, organization, loading: loadingOrganizationData } = useContext(UserAndOrganzationContext);
    const { subscriptionType, scanLimit, subscriptionFromOrganization } = useGetSubscription();

    const DEFAULT_NUMBER_OF_USERS = 5;
    const [numberOfUsers, setNumberOfUsers] = useState(DEFAULT_NUMBER_OF_USERS);

    const showDPAMessage = window.location.pathname.split("/")[window.location.pathname.split("/").length - 1] === "DPA";

    const getCountry = () => {
        if (window.location.origin.includes("verklig")) return "SE";
        if (window.location.origin.includes("gradr")) return "US";
        return "NO";
    };

    const priceId = priceIdOrganizationProfessional; //Premium for Schools

    const [paymentType, setPaymentType] = useState("card");
    const [organizationData, setOrganizationData] = useState({
        name: "",
        adress: "",
        city: "",
        postalNumber: "",
        country: getCountry(),
        type: "school",
        subscription: "waiting",
        paymentAdministratorUserId: null,
        numberOfUserLicences: 0,
    });

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(null);

    const functions = firebase.app().functions("europe-west1");
    const createOrganizationQuery = functions.httpsCallable("createOrganization");

    const createOrganization = async (e, priceId) => {
        e.preventDefault();

        if (loading || !validateForm()) return;

        setLoading(true);
        setLoadingMessage(t("new-organization.loading.organization"));

        try {
            //Create organization
            const result = await createOrganizationQuery({ organizationData: organizationData });
            const organizationId = result.data.id;

            //Create checkout-page link
            setLoadingMessage(t("new-organization.loading.subscription"));

            const stripe = await loadStripe("pk_live_51NTOXIKwREFQyFmXRZDU7ohPjdtDGzYxjN4rW7VsQRP5GUjFss8ljK9He5LAIkmcvFU7KfEYFai3QZWtKABUPhlu000FG2ds5E");

            const isTrial = subscriptionType === "waiting";
            const sessionId = await createCheckoutSession(priceId, isTrial, organizationId);

            if (sessionId) {
                const { error } = await stripe.redirectToCheckout({ sessionId });
                if (error) {
                    console.error("Error redirecting to checkout:", error);
                }
            }

            setLoading(false);
            setLoadingMessage(null);
            // window.location.href = `${window.location.origin}/organization`;
        } catch (error) {
            setLoading(false);
            setLoadingMessage(null);
            // navigate('/unavailable');
        }
    };

    const createCheckoutSession = async (priceId, isTrial, organizationId) => {
        const functions = firebase.app().functions("europe-west1");
        const createCheckoutSessionQuery = functions.httpsCallable("checkoutSession");
        const hostname = window.location.hostname;

        try {
            const result = await createCheckoutSessionQuery({ priceId, isTrial, hostname, organizationId, quantity: numberOfUsers });
            return result.data.sessionId;
        } catch (error) {
            console.error("Error creating checkout session:", error);
        }
    };

    const validateForm = () => {
        if (organizationData.name.length <= 1) return false;
        if (organizationData.adress.length <= 1) return false;
        if (organizationData.city.length <= 1) return false;
        if (organizationData.postalNumber <= 999) return false;
        if (organizationData.country.length <= 1) return false;
        if (
            organizationData.type !== "school" &&
            organizationData.type !== "highschool" &&
            organizationData.type !== "higherEducation" &&
            organizationData.type !== "other"
        )
            return false;
        if (!numberOfUsers || numberOfUsers < 5 || numberOfUsers > 55) return false;
        return true;
    };

    const pricePerUser = Number.parseInt(t("upgrade.schools.price-per-user")) ?? 35;

    if (loadingOrganizationData)
        return (
            <Page>
                <Loader></Loader>
            </Page>
        );

    return (
        <>
            <PricingLoader opened={loading} text={loadingMessage} />

            <Page>
                <div className={style.maxWidth}>
                    {showDPAMessage && (
                        <div className={style.dpaWarningWrapper}>
                            <Text fw="bold" fz="lg">
                                {t("new-organization.dpa.title")}
                            </Text>
                            <Text>{t("new-organization.dpa.description")}</Text>
                        </div>
                    )}

                    <h1>{t("new-organization.title")}</h1>
                    <p>{t("new-organization.description")}</p>
                    <br />

                    <Tabs defaultValue="annual-invoice">
                        <Tabs.List>
                            <Tabs.Tab value="annual-invoice">{t("new-organization.payment-invoice.title")}</Tabs.Tab>
                            <Tabs.Tab value="card">{t("new-organization.payment-card")}</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="annual-invoice">
                            <br />
                            <p>{t("new-organization.payment-invoice.description")}</p>
                            <Title mt={"0.5rem"} underline fz={"1.125rem"} fw={"400"} c={"dark"}>
                                {t("new-organization.payment-invoice.contact-email")}
                            </Title>
                            <br />

                            <p>{t("new-organization.payment-invoice.email-content.label")}</p>
                            <List>
                                <List.Item>{t("new-organization.payment-invoice.email-content.VAT-number")}</List.Item>
                                <List.Item>{t("new-organization.payment-invoice.email-content.number-of-user-licences")}</List.Item>
                                <List.Item>{t("new-organization.payment-invoice.email-content.e-invoicing")}</List.Item>
                            </List>
                        </Tabs.Panel>

                        <Tabs.Panel value="card">
                            <br />
                            <form className={style.formGrid} onSubmit={(e) => e.preventDefault()}>
                                <div>
                                    <label>{t("new-organization.form.name")}</label>
                                    <br />
                                    <input
                                        autoFocus
                                        type="text"
                                        className="input"
                                        value={organizationData.name}
                                        onChange={(e) => setOrganizationData({ ...organizationData, name: e.target.value })}
                                    ></input>
                                </div>

                                <div>
                                    <label>{t("new-organization.form.adress")}</label>
                                    <br />
                                    <input
                                        type="text"
                                        className="input"
                                        value={organizationData.adress}
                                        onChange={(e) => setOrganizationData({ ...organizationData, adress: e.target.value })}
                                    ></input>
                                </div>
                                <div className={style.cityGrid}>
                                    <div>
                                        <label>{t("new-organization.form.city")}</label>
                                        <br />
                                        <input
                                            type="text"
                                            className="input"
                                            value={organizationData.city}
                                            onChange={(e) => setOrganizationData({ ...organizationData, city: e.target.value })}
                                        ></input>
                                    </div>
                                    <div>
                                        <label>{t("new-organization.form.zip-code")}</label>
                                        <br />
                                        <input
                                            type="number"
                                            className="input"
                                            value={organizationData.postalNumber}
                                            onChange={(e) => setOrganizationData({ ...organizationData, postalNumber: e.target.value })}
                                        ></input>
                                    </div>
                                </div>

                                <div>
                                    <br />
                                    <label>{`${t("new-organization.form.type.label")}:`}</label>
                                    <div className={style.organizationTypeGrid}>
                                        <div>
                                            <input
                                                type="radio"
                                                id="school"
                                                checked={organizationData.type === "school"}
                                                onChange={(e) => {
                                                    e.target.checked && setOrganizationData({ ...organizationData, type: "school" });
                                                }}
                                            ></input>
                                            <label htmlFor="school" style={{ userSelect: "none" }}>
                                                {t("new-organization.form.type.school")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="highschool"
                                                checked={organizationData.type === "highschool"}
                                                onChange={(e) => {
                                                    e.target.checked && setOrganizationData({ ...organizationData, type: "highschool" });
                                                }}
                                            ></input>
                                            <label htmlFor="highschool" style={{ userSelect: "none" }}>
                                                {t("new-organization.form.type.highschool")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="higherEducation"
                                                checked={organizationData.type === "higherEducation"}
                                                onChange={(e) => {
                                                    e.target.checked && setOrganizationData({ ...organizationData, type: "higherEducation" });
                                                }}
                                            ></input>
                                            <label htmlFor="higherEducation" style={{ userSelect: "none" }}>
                                                {t("new-organization.form.type.higher-education")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="other"
                                                checked={organizationData.type === "other"}
                                                onChange={(e) => {
                                                    e.target.checked && setOrganizationData({ ...organizationData, type: "other" });
                                                }}
                                            ></input>
                                            <label htmlFor="other" style={{ userSelect: "none" }}>
                                                {t("new-organization.form.type.other")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <br />

                                <Stack spacing={"0.5rem"} mt={"1rem"} mb={"2rem"}>
                                    <label>{t("new-organization.form.number-of-users-label")}</label>
                                    <NumberInput
                                        size="md"
                                        maw={"15rem"}
                                        value={numberOfUsers}
                                        onChange={setNumberOfUsers}
                                        min={5}
                                        max={55}
                                        step={1}
                                        onBlur={() => {
                                            !numberOfUsers && setNumberOfUsers(DEFAULT_NUMBER_OF_USERS);
                                        }}
                                    />
                                </Stack>

                                <Divider />

                                {subscriptionType === "waiting" ? (
                                    <Stack spacing={"0rem"} my={"1rem"}>
                                        <Text fz={"1.25rem"} fw={"500"}>
                                            {t("upgrade.schools.trial-title")}
                                        </Text>

                                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">
                                            {t("upgrade.schools.trial-total-price", { price: pricePerUser * numberOfUsers })}
                                        </Text>
                                    </Stack>
                                ) : (
                                    <Stack spacing={"0rem"} my={"1rem"}>
                                        <Text fz={"1.25rem"} fw={"500"}>
                                            {t("upgrade.schools.total-price", { price: pricePerUser * numberOfUsers })}
                                        </Text>
                                        <Text fz={14} fw={"500"} color="rgb(80, 80, 80)">
                                            {t("upgrade.schools.billing-frequency-total")}
                                        </Text>
                                    </Stack>
                                )}

                                {scanLimit > 0 && !subscriptionFromOrganization && subscriptionType !== "trial" && (
                                    <div className={style.warningWrapper}>
                                        <Flex columnGap={"1rem"} align={"center"} justify={"space-between"}>
                                            <Text fz={14}>{t("new-organization.form.warning-existing-subscription")}</Text>
                                            <a href="https://billing.stripe.com/p/login/9AQdTc2OY5eIdz29AA" target="_blank" rel="noreferrer">
                                                <Button variant="filled" color="dark">
                                                    {t("upgrade.manage-subscription.title")}
                                                </Button>
                                            </a>
                                        </Flex>
                                    </div>
                                )}

                                <div className={style.actionGroup}>
                                    <IconButton
                                        primary
                                        text={t("new-organization.form.submit")}
                                        onClick={(e) => createOrganization(e, priceId)}
                                        disabled={!validateForm() || (scanLimit > 0 && !subscriptionFromOrganization && subscriptionType !== "trial")}
                                    />
                                </div>
                            </form>
                        </Tabs.Panel>
                    </Tabs>
                </div>
            </Page>
        </>
    );
};

export default NewOrganization;
